import {
  OperationStarted,
  OPERATION_STARTED,
  OperationFailed,
  OPERATION_FAILED,
  OperationSucceeded,
  OPERATION_SUCCEEDED,
  SnackbarInfo,
  SNACKBAR_INFO,
  SnackbarSuccess,
  SNACKBAR_SUCCESS,
  SnackbarError,
  SNACKBAR_ERROR,
  SnackbarClear,
  SNACKBAR_CLEAR,
} from './types';

export function operatoinStarted(): OperationStarted {
  return {
    type: OPERATION_STARTED,
  };
}

export function operatoinSucceeded(message?: string): OperationSucceeded {
  return {
    type: OPERATION_SUCCEEDED,
    message,
  };
}

export function operatoinFailed(message: string): OperationFailed {
  return {
    type: OPERATION_FAILED,
    message,
  };
}

export function snackbarInfo(message: string): SnackbarInfo {
  return {
    type: SNACKBAR_INFO,
    message,
  };
}

export function snackbarSuccess(message: string): SnackbarSuccess {
  return {
    type: SNACKBAR_SUCCESS,
    message,
  };
}

export function snackbarError(message: string): SnackbarError {
  return {
    type: SNACKBAR_ERROR,
    message,
  };
}

export function snackbarClear(key?: number): SnackbarClear {
  return {
    type: SNACKBAR_CLEAR,
    key,
  };
}
