import React from 'react';
import { TextField as MUITextField } from '@material-ui/core';
import { makeInputStyles } from './styles';

export const TextField: typeof MUITextField = (props) => {
  const classes = makeInputStyles();

  return (
    <MUITextField
      {...props}
      type="text"
      variant="filled"
      color="secondary"
      fullWidth
      className={classes.FormControl}
      InputLabelProps={{ className: classes.label }}
      InputProps={{ className: classes.input }}
    />
  );
};
