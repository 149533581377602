import { makeStyles } from '@material-ui/core/styles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';

export function getModalStyleDefinitions(theme: DefaultTheme) {
  return {
    paper: {
      margin: 'auto',
      borderRadius: '8px',
      outline: 'none',
      boxShadow:
        theme.palette.type === 'light'
          ? '0px 4px 12px rgba(0, 0, 0, 0.12)'
          : 'none',
    },
  };
}

export const makeModalStyles = makeStyles((theme) =>
  getModalStyleDefinitions(theme)
);
