import React from 'react';
import { Fab, IconButton } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import './ColorBox.css';
import { makeColorBoxStyles } from './ColorBox.styles';

export const ColorBox: React.FC<{
  width?: string;
  height?: string;
  onBack?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  submitButton?: boolean;
}> = ({
  width = '40rem',
  height = '48rem',
  children,
  onBack,
  submitButton,
}) => {
  const classes = makeColorBoxStyles(width, height);

  return (
    <div className={`color-box ${classes['color-box']}`}>
      {onBack && (
        <div className="color-box__back">
          <IconButton
            size="small"
            aria-label="Back"
            edge="start"
            onClick={onBack}
          >
            <ArrowBack fontSize="small" />
          </IconButton>
        </div>
      )}

      {children}

      {submitButton && (
        <div className="color-box__fab">
          <Fab
            color="secondary"
            aria-label="Next"
            style={{ marginLeft: 'auto' }}
            type="submit"
          >
            <ArrowForward />
          </Fab>
        </div>
      )}
    </div>
  );
};
