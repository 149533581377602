import React from 'react';
import { ListItem, ListItemProps } from './ListItem';
import './ListList.css';

export const ListList: React.FC<{
  lists: ListItemProps[];
  overflow?: 'scroll' | 'visible';
}> = (props) => {
  return (
    <div
      className="list-list"
      style={{
        overflow: props.overflow,
      }}
    >
      {props.lists.map((list) => (
        <ListItem key={list.id} {...list} />
      ))}
    </div>
  );
};
