import React from 'react';
import { InboxIcon, DefaultCategoryIcon } from '.';
import { CalIcon } from './CalIcon';
import { ReactComponent as BackPack } from './icons/categories/ic_category_backpack.svg';
import { ReactComponent as Bag } from './icons/categories/ic_category_bag.svg';
import { ReactComponent as Ball } from './icons/categories/ic_category_ball.svg';
import { ReactComponent as Bike } from './icons/categories/ic_category_bike.svg';
import { ReactComponent as Boat } from './icons/categories/ic_category_boat.svg';
import { ReactComponent as Bookmark1 } from './icons/categories/ic_category_bookmark-1.svg';
import { ReactComponent as Bookmark } from './icons/categories/ic_category_bookmark.svg';
import { ReactComponent as Bowl } from './icons/categories/ic_category_bowl.svg';
import { ReactComponent as Cam } from './icons/categories/ic_category_cam.svg';
import { ReactComponent as Car } from './icons/categories/ic_category_car.svg';
import { ReactComponent as Cart } from './icons/categories/ic_category_cart.svg';
import { ReactComponent as Computer } from './icons/categories/ic_category_computer.svg';
import { ReactComponent as FerrisWheel } from './icons/categories/ic_category_ferris_wheel.svg';
import { ReactComponent as Friends } from './icons/categories/ic_category_friends.svg';
import { ReactComponent as Golf } from './icons/categories/ic_category_golf.svg';
import { ReactComponent as Heart } from './icons/categories/ic_category_heart.svg';
import { ReactComponent as Idea } from './icons/categories/ic_category_idea.svg';
import { ReactComponent as Luggage } from './icons/categories/ic_category_luggage.svg';
import { ReactComponent as Meal } from './icons/categories/ic_category_meal.svg';
import { ReactComponent as Misc } from './icons/categories/ic_category_misc.svg';
import { ReactComponent as Montain } from './icons/categories/ic_category_mountains.svg';
import { ReactComponent as Parasol } from './icons/categories/ic_category_parasol.svg';
import { ReactComponent as Skateboard } from './icons/categories/ic_category_skateboard.svg';
import { ReactComponent as Skull } from './icons/categories/ic_category_skull.svg';
import { ReactComponent as Ticket } from './icons/categories/ic_category_ticket.svg';

export function withClass<T extends React.Component>(
  Component: typeof BackPack
) {
  return (props: T['props']) => <Component className="icon" />;
}

export const ListIcons: { [index: string]: typeof DefaultCategoryIcon } = {
  'ic_category_backpack.svg': withClass(BackPack),
  'ic_category_bag.svg': withClass(Bag),
  'ic_category_ball.svg': withClass(Ball),
  'ic_category_bike.svg': withClass(Bike),
  'ic_category_boat.svg': withClass(Boat),
  'ic_category_bookmark-1.svg': withClass(Bookmark1),
  'ic_category_bookmark.svg': withClass(Bookmark),
  'ic_category_bowl.svg': withClass(Bowl),
  'ic_category_cam.svg': withClass(Cam),
  'ic_category_car.svg': withClass(Car),
  'ic_category_cart.svg': withClass(Cart),
  'ic_category_computer.svg': withClass(Computer),
  'ic_category_ferris_wheel.svg': withClass(FerrisWheel),
  'ic_category_friends.svg': withClass(Friends),
  'ic_category_golf.svg': withClass(Golf),
  'ic_category_heart.svg': withClass(Heart),
  'ic_category_idea.svg': withClass(Idea),
  'ic_category_luggage.svg': withClass(Luggage),
  'ic_category_meal.svg': withClass(Meal),
  'ic_category_misc.svg': withClass(Misc),
  'ic_category_mountains.svg': withClass(Montain),
  'ic_category_parasol.svg': withClass(Parasol),
  'ic_category_skateboard.svg': withClass(Skateboard),
  'ic_category_skull.svg': withClass(Skull),
  'ic_category_ticket.svg': withClass(Ticket),
};

export const ListIcon: React.FC<{ filename: string | undefined }> = (props) => {
  if (props.filename && props.filename in ListIcons) {
    const LI = ListIcons[props.filename];
    return <LI />;
  }

  switch (props.filename) {
    case 'inbox.svg':
      return <InboxIcon />;
    case 'cal.svg':
      return <CalIcon />;
    default:
      return <DefaultCategoryIcon />;
  }
};
