import React from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { makeInputStyles } from './styles';
import { VisibilityIcon, VisibilityOffIcon } from '../ui/icons';

export const PasswordField: typeof TextField = (props) => {
  const classes = makeInputStyles();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      label="Password"
      variant="filled"
      color="secondary"
      fullWidth
      className={classes.FormControl}
      InputLabelProps={{ className: classes.label }}
      InputProps={{
        className: classes.input,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classes.icon}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
