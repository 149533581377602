import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Fab } from '@material-ui/core';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { makeTaskAddModalStyles } from './TaskAddModal.styles';
import Modal from '../../ui/Modal';
import { addTask, updateTask } from '../../../store/task/actions';
import { AppDispatch } from '../../../store';
import { Task } from '../../../store/task/types';

export const TaskAddModal: React.FC<ConnectedProps<typeof connector>> = ({
  open,
  onClose,
  addTask,
  edit,
}) => {
  const classes = makeTaskAddModalStyles();
  const [title, setTitle] = useState(edit ? edit.title : '');
  const placeholder = edit ? edit.title : 'Create a new task...';

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addTask({ title });
    onClose && onClose();
    setTitle('');
  };

  return (
    <Modal onClose={onClose} open={open} width="52rem" height="auto">
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <div
          style={{ padding: '1.6rem 1.6rem 3.2rem 1.6rem', display: 'flex' }}
        >
          <input
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            type="text"
            autoFocus={true}
            className={classes.textfield}
            placeholder={placeholder}
          />

          {/* <AvatarMale className="avatar" /> */}
        </div>

        <div className="color-box__fab">
          <Fab
            color="secondary"
            aria-label="Submit Task"
            style={{ marginLeft: 'auto' }}
            type="submit"
            disabled={!title}
          >
            <ArrowUpward />
          </Fab>
        </div>
      </form>
    </Modal>
  );
};

const mapDispatch = (
  dispatch: AppDispatch,
  props: {
    listId: string;
    open: boolean;
    onClose?: () => void;
    edit?: Task | false;
  }
) => ({
  addTask: (task: { title: string }) => {
    if (props.edit) {
      dispatch(updateTask(props.edit, task));
    } else {
      dispatch(addTask({ ...task, done: false, listId: props.listId }));
    }
  },
  ...props,
});

const connector = connect(undefined, mapDispatch);

export default connector(TaskAddModal);
