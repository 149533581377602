import { makeStyles } from '@material-ui/core/styles';
import { getModalStyleDefinitions } from '../../ui/Modal.styles';

export const makeTaskDetailsStyles = makeStyles((theme) => ({
  paper: {
    ...getModalStyleDefinitions(theme).paper,
    margin: '3.2rem 3.2rem 3.2rem 28rem',
    width: '100%',
  },
}));
