import { makeStyles } from '@material-ui/core/styles';

export const makeTaskAddModalStyles = makeStyles((theme) => ({
  textfield: {
    color: theme.palette.text.primary,
    background: 'transparent',
    border: 'none',
    'line-height': '2rem',
    'font-size': '1.6rem',
    flexGrow: 1,
    padding: '0',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: '#888',
    },
  },
}));
