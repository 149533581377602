// Entity Interfaces
export interface SnackbarMessage {
  key: number;
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

// State Interfaces
export interface SystemState {
  operationsRunning: number;
  messages: SnackbarMessage[];
}

// Describing the different ACTION NAMES available
export const OPERATION_STARTED = 'OPERATION_STARTED';
export const OPERATION_SUCCEEDED = 'OPERATION_SUCCEEDED';
export const OPERATION_FAILED = 'OPERATION_FAILED';
export const SNACKBAR_INFO = 'SNACKBAR_INFO';
export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

// Action Interfaces
export interface OperationStarted {
  type: typeof OPERATION_STARTED;
}

export interface OperationFailed {
  type: typeof OPERATION_FAILED;
  message: string;
}

export interface OperationSucceeded {
  type: typeof OPERATION_SUCCEEDED;
  message?: string;
}

export interface SnackbarInfo {
  type: typeof SNACKBAR_INFO;
  message: string;
}

export interface SnackbarSuccess {
  type: typeof SNACKBAR_SUCCESS;
  message: string;
}

export interface SnackbarError {
  type: typeof SNACKBAR_ERROR;
  message: string;
}

export interface SnackbarClear {
  type: typeof SNACKBAR_CLEAR;
  key?: number;
}

// Action Union
export type SystemActionTypes =
  | OperationStarted
  | OperationFailed
  | OperationSucceeded
  | SnackbarInfo
  | SnackbarSuccess
  | SnackbarError
  | SnackbarClear;
