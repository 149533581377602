import { makeStyles } from '@material-ui/core/styles';

export const makeListAddFormStyles = makeStyles((theme) => {
  return {
    button: {
      minWidth: '5.6rem',
      padding: '12px 0 4px',
      marginRight: '1.2rem',
      letterSpacing: '0.4px',
      'text-transform': 'none',
      'font-weight': 'normal',
      color: '#888',
      background: theme.colorHoverOnBox,
      '&:hover': {
        color: theme.palette.text.primary,
        background: theme.colorHoverOnIcon,
      },
      '& .MuiButton-label': {
        flexFlow: 'column',
        fontSize: '1.2rem !important',
        lineHeight: '1.6rem !important',
      },
    },
    icon: {
      padding: '16px 0 16px',
      margin: '0.8rem 0.9rem',
      borderRadius: '8px',
      background: theme.colorHoverOnBox,
      color: theme.palette.text.primary,
    },
    iconActive: {
      background: `${theme.palette.text.primary} !important`,
      color: `${theme.palette.secondary.contrastText} !important`,
    },
  };
});
