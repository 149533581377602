import { AppState, store as appStore } from '..';
import { taskRefresh } from './actions';

function select(state: AppState): Date | undefined {
  const now = new Date();
  const nextRefreshes = state.tasks
    .filter((task) => task.refresh && task.refresh > now)
    .map((task) => task.refresh)
    .sort((a, b) => a!.getTime() - b!.getTime());
  return nextRefreshes.length ? nextRefreshes[0] : undefined;
}

let nextRefresh: Date | undefined = undefined;

export function handleChange(store: typeof appStore) {
  return () => {
    let previousValue = nextRefresh;
    nextRefresh = select(store.getState());

    if (previousValue !== nextRefresh) {
      if (nextRefresh) {
        process.env.NODE_ENV !== 'test' &&
          console.log('Tasks will be refreshed:', nextRefresh);
        setTimeout(
          () => store.dispatch(taskRefresh()),
          nextRefresh.getTime() - new Date().getTime()
        );
      }
    }
  };
}

export function refreshTasks(store: typeof appStore = appStore) {
  const unsubscribe = store.subscribe(handleChange(store));

  return () => {
    unsubscribe();
    nextRefresh = undefined;
  };
}
