import {
  SystemState,
  OPERATION_STARTED,
  OPERATION_SUCCEEDED,
  OPERATION_FAILED,
  SystemActionTypes,
  SNACKBAR_INFO,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
  SNACKBAR_CLEAR,
} from './types';

const initialState: SystemState = {
  operationsRunning: 0,
  messages: [],
};

export function systemReducer(
  state = initialState,
  action: SystemActionTypes
): SystemState {
  switch (action.type) {
    case OPERATION_STARTED:
      return {
        operationsRunning: state.operationsRunning + 1,
        messages: state.messages,
      };
    case OPERATION_SUCCEEDED:
      return {
        operationsRunning: state.operationsRunning - 1,
        messages: getMessages(action, state, 'success'),
      };
    case OPERATION_FAILED:
      return {
        operationsRunning: state.operationsRunning - 1,
        messages: getMessages(action, state, 'error'),
      };
    case SNACKBAR_INFO:
      return {
        operationsRunning: state.operationsRunning,
        messages: getMessages(action, state, 'info'),
      };
    case SNACKBAR_SUCCESS:
      return {
        operationsRunning: state.operationsRunning,
        messages: getMessages(action, state, 'success'),
      };
    case SNACKBAR_ERROR:
      return {
        operationsRunning: state.operationsRunning,
        messages: getMessages(action, state, 'error'),
      };
    case SNACKBAR_CLEAR:
      return {
        operationsRunning: state.operationsRunning,
        messages: action.key
          ? state.messages.filter((message) => message.key !== action.key)
          : [],
      };
    default:
      return state;
  }
}

function getMessages(
  action: SystemActionTypes & { message?: string },
  state: SystemState,
  severity: 'error' | 'warning' | 'info' | 'success'
) {
  return action.message
    ? [
        ...state.messages,
        {
          key: new Date().getTime(),
          severity,
          message: action.message,
        },
      ]
    : state.messages;
}
