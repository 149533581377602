import { makeStyles } from '@material-ui/core/styles';

export const makeInputStyles = makeStyles((theme) => ({
  FormControl: {
    '& .MuiFilledInput-root': { background: theme.colorHoverOnBox },
  },
  input: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    paddingBottom: '2px',
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: '1.6rem',
    lineHeight: '1.8rem',
    '&.Mui-focused': { color: theme.palette.text.secondary },
    '&.MuiInputLabel-shrink': { marginTop: '3px' },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

export const makeButtonStyles = makeStyles((theme) => ({
  button: {
    'border-width': '2px',
    //color: theme.palette.text.secondary,
    'font-size': '1.4rem',
    'font-weight': 'normal',
    'line-height': '1.43',
    padding: '6px 12px',
    'text-transform': 'none',
    //'&:hover': { color: theme.palette.secondary.main },
    '&.MuiButton-contained': { padding: '8px 14px' },
    '&.MuiButton-outlined': { color: theme.palette.text.secondary },
  },
}));

export const makeDatePickerDialogStyles = makeStyles((theme) => ({
  dialog: {
    background: theme.colorBox,
    borderRadius: '4px',
    boxShadow:
      theme.palette.type === 'light'
        ? '0px 4px 12px rgba(0, 0, 0, 0.12)'
        : 'none',
  },
}));
