import {
  TaskState,
  TaskActionTypes,
  TASK_UPDATE_RECEIVED,
  TASK_REMOVED_RECEIVED,
  TASK_REFRESH,
} from './types';
import { LOGGED_OUT } from '../auth/types';

const initialState: TaskState = [];

export function taskReducer(
  state = initialState,
  action: TaskActionTypes
): TaskState {
  switch (action.type) {
    case TASK_UPDATE_RECEIVED:
      let updated = false;
      const tasks = state.map((task) => {
        if (task.id === action.task.id) {
          updated = true;
          return { ...action.task };
        } else {
          return task;
        }
      });
      return updateRefresh(updated ? tasks : [...tasks, action.task]);
    case TASK_REMOVED_RECEIVED:
      return updateRefresh([
        ...state.filter((task) => task.id !== action.taskId),
      ]);
    case TASK_REFRESH:
      return updateRefresh(state);
    case LOGGED_OUT:
      return [];
    default:
      return state;
  }
}

function updateRefresh(tasks: TaskState): TaskState {
  const now = new Date();

  return tasks.map((task) => {
    let refresh: Date | undefined = undefined;

    if (task.snoozed && task.snoozed > now) {
      // Refresh task on the date it is snoozed
      refresh = task.snoozed;
    }

    if (refresh === task.refresh) {
      return task;
    } else {
      return { ...task, refresh };
    }
  });
}
