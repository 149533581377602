import React, { useState } from 'react';
import useInterval from 'use-interval';
import './style.css';
import { ReactComponent as CloudyIcon } from './icons/weather/cloudy.svg';
import { ReactComponent as FoggyIcon } from './icons/weather/foggy.svg';
import { ReactComponent as MixedIcon } from './icons/weather/mixed.svg';
import { ReactComponent as NightIcon } from './icons/weather/night.svg';
import { ReactComponent as RainIcon } from './icons/weather/rain.svg';
import { ReactComponent as SnowIcon } from './icons/weather/snow.svg';
import { ReactComponent as SunnyIcon } from './icons/weather/sunny.svg';
import { ReactComponent as ThunderIcon } from './icons/weather/thunder.svg';
import { getWeatherIcon, OpenWeatherIcon } from '../../../utils/weather';

CloudyIcon.defaultProps = { className: 'weather-icon' };
FoggyIcon.defaultProps = { className: 'weather-icon' };
MixedIcon.defaultProps = { className: 'weather-icon' };
NightIcon.defaultProps = { className: 'weather-icon' };
RainIcon.defaultProps = { className: 'weather-icon' };
SnowIcon.defaultProps = { className: 'weather-icon' };
SunnyIcon.defaultProps = { className: 'weather-icon' };
ThunderIcon.defaultProps = { className: 'weather-icon' };

const Weather: React.FC = () => {
  const [Icon, setIcon] = useState<typeof SunnyIcon | undefined>(undefined);

  useInterval(
    () => {
      getWeatherIcon().then((icon) => setIcon(mapIcon(icon)));
    },
    1000 * 60 * 15,
    true
  );

  return Icon ? <Icon /> : null;
};

function mapIcon(
  icon: OpenWeatherIcon | undefined
): typeof SunnyIcon | undefined {
  switch (icon) {
    case '01d':
      return SunnyIcon;
    case '01n':
      return NightIcon;
    case '02d':
      return MixedIcon;
    case '02n':
      return MixedIcon;
    case '03d':
      return CloudyIcon;
    case '03n':
      return CloudyIcon;
    case '04d':
      return CloudyIcon;
    case '04n':
      return CloudyIcon;
    case '09d':
      return RainIcon;
    case '09n':
      return RainIcon;
    case '10d':
      return RainIcon;
    case '10n':
      return RainIcon;
    case '11d':
      return ThunderIcon;
    case '11n':
      return ThunderIcon;
    case '13d':
      return SnowIcon;
    case '13n':
      return SnowIcon;
    case '50d':
      return FoggyIcon;
    case '50n':
      return FoggyIcon;
  }
}

export {
  CloudyIcon,
  FoggyIcon,
  MixedIcon,
  NightIcon,
  RainIcon,
  SnowIcon,
  SunnyIcon,
  ThunderIcon,
  Weather,
};
