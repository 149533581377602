import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import startOfWeek from 'date-fns/startOfWeek';
import addWeeks from 'date-fns/addWeeks';
import addHours from 'date-fns/addHours';
import addSeconds from 'date-fns/addSeconds';
import { AppDispatch } from '../../../store';
import {
  OneHourIcon,
  TomorrowIcon,
  NextWeekIcon,
  DateIcon,
} from '../../ui/icons';
import { DatePicker } from '../../forms/DatePicker';
import TaskItem, { TaskItemProps } from './TaskItem';
import './TaskList.css';
import { makeSnoozeMenuStyles } from './TaskList.styles';
import { snoozeTask } from '../../../store/task/actions';
import { List } from '../../../store/list/types';

export const TaskList: React.FC<ConnectedProps<typeof connector>> = (props) => {
  const [taskId, setTaskId] = React.useState('');
  const [listId, setListId] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = makeSnoozeMenuStyles();

  const openDialog = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const closeDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setDialogOpen(false);
  };

  const openMenu = (
    taskId: string,
    listId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setTaskId(taskId);
    setListId(listId);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div className="task-list">
        {props.tasks.map((task) => (
          <TaskItem
            key={task.id}
            {...task}
            openedIn={props.openedIn}
            openMenu={openMenu}
          />
        ))}
      </div>

      <Menu
        id="snooze-menu"
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        PaperProps={{ className: classes.menu }}
        MenuListProps={{
          subheader: (
            <ListSubheader component="div">Snooze until</ListSubheader>
          ),
        }}
      >
        <MenuItem
          onClick={async (e) => {
            const in15Seconds = addSeconds(new Date(), 15);
            props.snoozeTask(taskId, listId, in15Seconds);
            closeMenu(e);
          }}
        >
          <ListItemIcon>
            <OneHourIcon />
          </ListItemIcon>
          <ListItemText primary="In 15 seconds" />
        </MenuItem>

        <MenuItem
          onClick={async (e) => {
            const inOneHour = addHours(new Date(), 1);
            props.snoozeTask(taskId, listId, inOneHour);
            closeMenu(e);
          }}
        >
          <ListItemIcon>
            <OneHourIcon />
          </ListItemIcon>
          <ListItemText primary="In 1 hour" />
        </MenuItem>

        <MenuItem
          onClick={async (e) => {
            const tomorrow = addHours(startOfTomorrow(), 8);
            props.snoozeTask(taskId, listId, tomorrow);
            closeMenu(e);
          }}
        >
          <ListItemIcon>
            <TomorrowIcon />
          </ListItemIcon>
          <ListItemText primary="Tomorrow" secondary="08:00" />
        </MenuItem>

        <MenuItem
          onClick={async (e) => {
            const nextWeek = addHours(
              addWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 8),
              1
            );
            props.snoozeTask(taskId, listId, nextWeek);
            closeMenu(e);
          }}
        >
          <ListItemIcon>
            <NextWeekIcon />
          </ListItemIcon>
          <ListItemText primary="Next week" secondary="Mon, 08:00" />
        </MenuItem>

        <Divider style={{ margin: '8px 0' }} />

        <MenuItem
          onClick={async (e) => {
            openDialog(e);
            closeMenu(e);
          }}
        >
          <ListItemIcon>
            <DateIcon />
          </ListItemIcon>
          <ListItemText primary="Select a date" />
        </MenuItem>
      </Menu>

      <DatePicker
        open={dialogOpen}
        onClose={closeDialog}
        onAccept={(e, date) => {
          if (date) {
            props.snoozeTask(taskId, listId, date);
          }
          closeDialog(e);
        }}
      />
    </React.Fragment>
  );
};

const mapDispatch = (
  dispatch: AppDispatch,
  props: {
    tasks: TaskItemProps[];
    openedIn: List;
  }
) => ({
  snoozeTask: (taskId: string, listId: string, until: Date) => {
    dispatch(
      snoozeTask(
        {
          id: taskId,
          listId: listId,
        },
        until
      )
    );
  },
  ...props,
});

const connector = connect(undefined, mapDispatch);

export default connector(TaskList);
