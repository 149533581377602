import React from 'react';
import Lottie from 'react-lottie';
import animationData from './lottie/checkbox_completed_green.json';

const CircleCheckedAnimated: React.FC<{ onComplete?: () => void }> = ({
  onComplete,
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={24}
      width={24}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => {
            onComplete && onComplete();
          },
        },
      ]}
    />
  );
};

export default CircleCheckedAnimated;
