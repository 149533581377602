import { useEffect } from 'react';
import { startListSyncing } from '../store/list/actions';
import { store } from '../store';

export const useListSyncing = () => {
  useEffect(() => {
    process.env.NODE_ENV !== 'test' && console.log('Start syncing lists');
    const unsubscribe = store.dispatch(startListSyncing());

    return () => {
      process.env.NODE_ENV !== 'test' && console.log('Stop syncing lists');
      unsubscribe();
    };
  }, []);
};
