import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

// See Android Implementation: https://github.com/vanniktech/wando-android/blob/master/shared/src/main/res/values/colors.xml
// const swipe_green = '#4CAF50';
const just_white = '#FFFFFF';
const white_background = '#F7F7F7';
const white_background_darker = '#efefef';
const just_black = '#000000';
const anthracite = '#121212';
const weird_color = '#888888';
const black_01 = '#1D1D1D';
const black_02 = '#2B2B2B';
const black_022 = '#222222';
const black_033 = '#333333';
const grey_04 = '#4E4E4E';
const grey_0A = '#ADADAD';

// See Android Implementation: https://github.com/vanniktech/wando-android/blob/master/shared/src/main/res/values/colors.xml
export const colorsLightTheme = {
  colorPrimary: black_02,
  colorSecondary: anthracite,
  colorBackground: white_background,
  colorTextSecondary: weird_color,
  colorTextPrimary: grey_0A,
  colorText: just_black,
  colorDark: just_white,
  colorDrawer: white_background,
  colorBox: just_white,
  colorHover: just_white,
  colorHoverOnBox: white_background,
  colorHoverOnIcon: white_background_darker,
  colorRipple: weird_color,
  colorOnPrimary: just_black,
  colorOnSecondary: just_white,
  colorSeperator: 'rgba(0, 0, 0, 0.08)',
  colorSnackbar: black_033,
};

// See Android Implementation: https://github.com/vanniktech/wando-android/blob/master/shared/src/main/res/values-night/colors.xml
export const colorsDarkTheme = {
  colorPrimary: anthracite,
  colorSecondary: just_white,
  colorBackground: anthracite,
  colorTextSecondary: weird_color,
  colorTextPrimary: grey_04,
  colorText: just_white,
  colorDark: just_black,
  colorDrawer: black_01,
  colorBox: black_022,
  colorHover: black_033,
  colorHoverOnBox: black_033,
  colorHoverOnIcon: grey_04,
  colorRipple: weird_color,
  colorOnPrimary: just_white,
  colorOnSecondary: just_black,
  colorSeperator: 'rgba(255, 255, 255, 0.08)',
  colorSnackbar: just_white,
};

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colorBox: string;
    colorDrawer: string;
    colorTextSecondary: string;
    colorTextPrimary: string;
    colorHover: string;
    colorHoverOnBox: string;
    colorHoverOnIcon: string;
    colorSeperator: string;
    colorSnackbar: string;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    colorBox: string;
    colorDrawer: string;
    colorTextSecondary: string;
    colorTextPrimary: string;
    colorHover: string;
    colorHoverOnBox: string;
    colorHoverOnIcon: string;
    colorSeperator: string;
    colorSnackbar: string;
  }
}

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

// See Android Implementation: https://github.com/vanniktech/wando-android/blob/master/shared/src/main/res/values/themes.xml
function createTheme(type: 'dark' | 'light', color: typeof colorsLightTheme) {
  return createMuiTheme({
    palette: {
      type,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: color.colorPrimary,
        dark: color.colorDark,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: color.colorSecondary,
        light: 'red',
        contrastText: color.colorOnSecondary,
      },
      background: { default: color.colorBackground, paper: color.colorBox },
      text: {
        primary: color.colorOnPrimary,
        secondary: color.colorTextSecondary,
        // disabled: string, ??
        // hint: string, ??
      },
    },
    typography: {
      fontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ].join(','),
      fontSize: 14,
      htmlFontSize: 10,
    },
    colorBox: color.colorBox,
    colorDrawer: color.colorDrawer,
    colorTextSecondary: color.colorTextSecondary,
    colorTextPrimary: color.colorTextPrimary,
    colorHover: color.colorHover,
    colorHoverOnBox: color.colorHoverOnBox,
    colorHoverOnIcon: color.colorHoverOnIcon,
    colorSeperator: color.colorSeperator,
    colorSnackbar: color.colorSnackbar,
    overrides: {
      MuiPickersDay: {
        daySelected: {
          backgroundColor: color.colorOnPrimary,
          color: color.colorOnSecondary,
          '&:hover': {
            willChange: 'background-color',
            backgroundColor: color.colorOnPrimary,
          },
        },
      },
    },
  });
}

export const darkTheme = createTheme('dark', colorsDarkTheme);
export const lightTheme = createTheme('light', colorsLightTheme);
