import {
  List,
  LIST_UPDATE_RECEIVED,
  ListUpdateReceived,
  LIST_REMOVED_RECEIVED,
  ListRemovedReceived,
  DBList,
} from './types';
import { firestore } from '../../firebase';
import { ThunkResult } from '..';
import { snackbarError } from '../system/actions';

export function addList(
  list: Omit<Omit<List, 'id'>, 'createdBy'>
): ThunkResult<Promise<String | undefined>> {
  return async (dispatch, getState) => {
    const createdBy = getState().auth.user?.uid;

    try {
      const ref = await firestore
        .collection('lists')
        .add({ ...list, createdBy });
      return ref.id;
    } catch (error) {
      dispatch(snackbarError(error.message));
    }
  };
}

export function updateList(
  list: { id: string },
  updates: Partial<Omit<Omit<List, 'id'>, 'createdBy'>>
): ThunkResult<Promise<String | undefined>> {
  return async (dispatch, getState) => {
    try {
      await firestore
        .collection('lists')
        .doc(list.id)
        .update({ ...updates });
      return list.id;
    } catch (error) {
      dispatch(snackbarError(error.message));
    }
  };
}

export function deleteList(list: {
  id: string;
}): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    try {
      await firestore.collection('lists').doc(list.id).delete();
      return true;
    } catch (error) {
      dispatch(snackbarError(error.message));
      return false;
    }
  };
}

export function listUpdateReceived(list: List): ListUpdateReceived {
  return {
    type: LIST_UPDATE_RECEIVED,
    list,
  };
}
export function listRemovedReceived(listId: string): ListRemovedReceived {
  return {
    type: LIST_REMOVED_RECEIVED,
    listId,
  };
}

export function startListSyncing(): ThunkResult<() => void> {
  return (dispatch, getState) => {
    const createdBy = getState().auth.user?.uid;

    const unsubscribe = firestore
      .collection('lists')
      .where('createdBy', '==', createdBy)
      .onSnapshot(
        function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.type === 'removed') {
              dispatch(listRemovedReceived(change.doc.id));
            } else {
              const dbList = change.doc.data() as DBList;
              const list: List = {
                id: change.doc.id,
                createdBy: dbList.createdBy,
                name: dbList.name,
                description: dbList.description || '',
                icon: dbList.icon || 'default.svg',
              };
              dispatch(listUpdateReceived(list));
            }
          });
        },
        function (error) {
          dispatch(snackbarError(error.message));
        }
      );

    // Here you could dispatch an action that sets a syncing state:
    // dispatch(listSyncingStarted());

    return () => {
      unsubscribe();
      // Here you could dispatch an action that unsets a syncing state:
      // dispatch(listSyncingStopped());
    };
  };
}
