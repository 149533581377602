import {
  ListState,
  ListActionTypes,
  LIST_UPDATE_RECEIVED,
  LIST_REMOVED_RECEIVED,
} from './types';
import { LOGGED_OUT } from '../auth/types';

const initialState: ListState = [];

export function listReducer(
  state = initialState,
  action: ListActionTypes
): ListState {
  switch (action.type) {
    case LIST_UPDATE_RECEIVED:
      let updated = false;
      const lists = state.map((list) => {
        if (list.id === action.list.id) {
          updated = true;
          return {
            ...list,
            ...action.list,
          };
        } else {
          return list;
        }
      });
      return updated ? lists : [...lists, action.list];
    case LIST_REMOVED_RECEIVED:
      return [...state.filter((list) => list.id !== action.listId)];
    case LOGGED_OUT:
      return [];
    default:
      return state;
  }
}
