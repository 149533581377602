import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './DashboardDetails.css';
import { makeDashboardDetailsStyles } from './DashboardDetails.styles';
import { AppState } from '../../store';
import { List } from '../../store/list/types';
import TaskList from './tasks/TaskList';
import { taskSelector, DYNAMIC_LIST_IDS } from '../../selectors/task';
import TaskAddModal from './tasks/TaskAddModal';
import DashboardDetailsActionMenu from './DashboardDetailsActionMenu';
import ListEditModal from './lists/ListEditModal';
import { AvatarMale } from '../ui/icons';
import { Task } from '../../store/task/types';
import TaskDetailsModal from './tasks/TaskDetailsModal';
import { EmptyStateIllustration } from '../ui/illustrations/EmptyStateIllustration';

export const DashboardDetails: React.FC<ConnectedProps<typeof connector>> = ({
  list,
  tasks,
  editList,
  addTask,
  editTask,
  taskDetails,
}) => {
  const classes = makeDashboardDetailsStyles();
  const history = useHistory();
  const [showsSnoozedTasks, setShowsSnoozedTasks] = useState(false);
  const [showsDoneTasks, setShowsDoneTasks] = useState(false);
  editTask = editTask && !!taskDetails && !addTask;

  const showTaskAddModal = () => {
    if (!editTask) {
      history.push(`/lists/${list.id}/add`);
    }
  };
  const closeTaskAddModal = () => {
    if (editTask) {
      history.push(`/lists/${list.id}`);
    } else {
      history.push(`/lists/${list.id}`);
    }
  };

  return (
    <div className="list-details">
      <Helmet>
        <title>{list.name} - Wando</title>
      </Helmet>

      <div className="list-details__header">
        <div className="list-details__header__title">
          <h1>{list.name}</h1>
          {list.description && (
            <p className={classes['list-details__description']}>
              {list.description}
            </p>
          )}
        </div>
        <div className="list-details__header__actions">
          {/*
          <IconButton color="inherit" aria-label="Notifications">
            <AlertIcon />
          </IconButton>
          */}
          <DashboardDetailsActionMenu list={list} />
          {!DYNAMIC_LIST_IDS.includes(list.id) && (
            <AvatarMale className="avatar" />
          )}
        </div>
      </div>

      {tasks.open.length === 0 && <EmptyStateIllustration />}

      {tasks.open.length > 0 && (
        <div className="section">
          <h2 className={`section__title ${classes.section__title}`}>
            Open Tasks
          </h2>
          {/* TODO: Split Tasks in due today, due not today and done */}
          <TaskList
            openedIn={list}
            tasks={tasks.open.map((task) => ({
              ...task,
              avatar: 'https://www.w3schools.com/howto/img_avatar.png',
              subtasks: false,
              attachments: false,
              snoozed: false,
            }))}
          />
        </div>
      )}

      {tasks.snoozed.length > 0 && (
        <div className="section">
          <h2 className={`section__title ${classes.section__title}`}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowsSnoozedTasks(!showsSnoozedTasks);
              }}
              color="inherit"
            >
              Tasks Snoozed
              {showsSnoozedTasks ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </h2>
          {showsSnoozedTasks && (
            <TaskList
              openedIn={list}
              tasks={tasks.snoozed.map((task) => ({
                ...task,
                avatar: 'https://www.w3schools.com/howto/img_avatar.png',
                subtasks: false,
                attachments: false,
                snoozed: true,
              }))}
            />
          )}
        </div>
      )}

      {tasks.done.length > 0 && (
        <div className="section">
          <h2 className={`section__title ${classes.section__title}`}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowsDoneTasks(!showsDoneTasks);
              }}
              color="inherit"
            >
              Tasks Done
              {showsDoneTasks ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </h2>

          {showsDoneTasks && (
            <TaskList
              openedIn={list}
              tasks={tasks.done.map((task) => ({
                ...task,
                avatar: 'https://www.w3schools.com/howto/img_avatar.png',
                subtasks: false,
                attachments: false,
                snoozed: false,
              }))}
            />
          )}
        </div>
      )}

      {!editTask && (
        <div className="list-details__task-add">
          <Fab
            color="secondary"
            aria-label="Add task"
            onClick={showTaskAddModal}
          >
            <AddIcon />
          </Fab>
        </div>
      )}

      {(addTask || editTask) && (
        <TaskAddModal
          open={addTask || !!editTask}
          onClose={closeTaskAddModal}
          listId={list.id}
          edit={editTask && taskDetails}
        />
      )}

      {!editTask && taskDetails && (
        <TaskDetailsModal
          taskId={taskDetails.id}
          openedIn={list}
          open={!!taskDetails}
          close={() => {
            history.push(`/lists/${list.id}`);
          }}
        />
      )}

      <ListEditModal
        listId={list.id}
        open={editList === true}
        close={() => {
          history.push(`/lists/${list.id}`);
        }}
      />
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  props: {
    list: List;
    taskDetails?: Task;
    editList?: boolean;
    addTask?: boolean;
    editTask?: boolean;
  }
) => ({
  ...props,
  tasks: taskSelector(state.tasks, props.list.id),
});

const connector = connect(mapStateToProps, {});

export default connector(DashboardDetails);
