import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Div100vh from 'react-div-100vh';
import DashboardPage from '../dashboard/DashboardPage';
import { LoadingSpinner } from '../ui/LoadingSpinner';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../store';
import OnboardingPage from './OnboardingRouter';
import { useAuthentication } from '../../hooks/useAuthentication';
import ProfileUpdatePage from '../onboarding/account/ProfileUpdatePage';
import PasswordResetPage from '../onboarding/password/PasswordResetPage';
import { LIST_ID_INBOX } from '../../selectors/task';

export const AppRouter: React.FC<ConnectedProps<typeof connector>> = ({
  authenticated,
  requiresProfileCompletion,
  authStateDetermined,
}) => {
  useAuthentication();
  const history = useHistory();

  const classes = makeStyles((theme) => ({
    app: {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      height: '100vh',
      width: '100vw',
      minWidth: '89.6rem',
    },
  }))();

  return (
    <Div100vh className={classes.app}>
      <Helmet>
        <title>Wando</title>
      </Helmet>
      <Switch>
        <Route
          path="/lists/:listId/tasks/:taskId/:action?"
          render={(route) => {
            if (authenticated) {
              if (requiresProfileCompletion) return <ProfileUpdatePage />;
              return (
                <DashboardPage
                  selectedList={route.match.params.listId}
                  selectedTask={route.match.params.taskId}
                  editTask={route.match.params.action === 'edit'}
                />
              );
            } else {
              if (authStateDetermined) return <Redirect to="/signup" />;
              else return <LoadingSpinner show={true} />;
            }
          }}
        />
        <Route
          path="/lists/:listId/:action?"
          render={(route) => {
            if (authenticated) {
              if (requiresProfileCompletion) return <ProfileUpdatePage />;
              return (
                <DashboardPage
                  selectedList={route.match.params.listId}
                  editList={route.match.params.action === 'edit'}
                  addTask={route.match.params.action === 'add'}
                />
              );
            } else {
              if (authStateDetermined) return <Redirect to="/signup" />;
              else return <LoadingSpinner show={true} />;
            }
          }}
        />
        <Route path="/" exact={true}>
          <Redirect to={`/lists/${LIST_ID_INBOX}`} />
        </Route>
        <Route path="/signup">
          {!authenticated ? <OnboardingPage /> : <Redirect to="/" />}
        </Route>
        <Route path="/reset-password">
          {!authenticated ? <PasswordResetPage /> : <Redirect to="/" />}
        </Route>
        <Route path="/account">
          {authenticated ? (
            <ProfileUpdatePage onClose={() => history.push('/')} />
          ) : authStateDetermined ? (
            <Redirect to="/signup" />
          ) : (
            <LoadingSpinner show={true} />
          )}
        </Route>
        <Route>
          <div style={{ padding: '4.8rem' }}>
            <h1>404: Not found</h1>
            <h2>TODO: Implement nice 404 page!</h2>
          </div>
        </Route>
      </Switch>
    </Div100vh>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticated: state.auth.user !== null,
  requiresProfileCompletion:
    state.auth.user &&
    !state.auth.user.isAnonymous &&
    !state.auth.user.displayName,
  authStateDetermined: state.auth.authStateDetermined,
});
const connector = connect(mapStateToProps, {});

export default connector(AppRouter);
