import React from 'react';
import { Grow } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import { makeListAddFormStyles } from './ListAdd.styles';
import { ListIcons } from '../../ui/icons/ListIcon';

export const ListAddIconSelector: React.FC<{
  icon: string;
  close?: () => void;
  onChange?: (name: string) => void;
}> = (props) => {
  const classes = makeListAddFormStyles();

  return (
    <div>
      <div style={{ marginBottom: '6.4rem', display: 'flex' }}>
        <IconButton
          size="small"
          aria-label="Close"
          edge="start"
          onClick={props.close}
          style={{ display: 'block', padding: '2px', color: '#888' }}
        >
          <Close fontSize="small" color="inherit" />
        </IconButton>
        <div
          style={{
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
            letterSpacing: '0.002em',
            margin: '0 1.6rem',
          }}
        >
          Choose your list icon
        </div>
      </div>

      <div style={{ margin: '-0.8rem -0.9rem' }}>
        {Object.keys(ListIcons).map((filename, index) => {
          const Icon = ListIcons[filename];
          return (
            <Grow in={true} key={filename} timeout={50 * index}>
              <Button
                size="small"
                className={`${classes.button} ${classes.icon} ${
                  filename === props.icon ? classes.iconActive : ''
                }`}
                color="inherit"
                onClick={() => {
                  props.onChange && props.onChange(filename);
                }}
              >
                <Icon />
              </Button>
            </Grow>
          );
        })}
      </div>
    </div>
  );
};

export default ListAddIconSelector;
