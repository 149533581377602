import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { AppDispatch } from '../../store';
import { EditIcon, TrashIcon } from '../ui/icons';
import { deleteList } from '../../store/list/actions';
import { List } from '../../store/list/types';
import { DYNAMIC_LIST_IDS } from '../../selectors/task';
import { makeDashboardDetailsActionMenuStyles } from './DashboardDetailsActionMenu.styles';

export const DashboardDetailsActionMenu: React.FC<ConnectedProps<
  typeof connector
>> = (props) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = makeDashboardDetailsActionMenuStyles();

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (DYNAMIC_LIST_IDS.includes(props.list.id)) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="List Action Menu"
        aria-controls="list-action-menu"
        aria-haspopup="true"
        onClick={openMenu}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="list-action-menu"
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{ className: classes.menu }}
      >
        <MenuItem
          onClick={async () => {
            history.push(`/lists/${props.list.id}/edit`);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit List" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDialog();
            closeMenu();
          }}
          style={{ color: '#FF6666' }}
        >
          <ListItemIcon>
            <TrashIcon color="#FF6666" />
          </ListItemIcon>
          <ListItemText primary="Delete List" />
        </MenuItem>
      </Menu>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ classes: { root: classes.dialog } }}
      >
        <DialogTitle id="alert-dialog-title">Delete List?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this list permanently?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            onClick={async () => {
              history.push('/lists/INBOX');
              props.deleteList();
              closeDialog();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapDispatch = (dispatch: AppDispatch, props: { list: List }) => ({
  ...props,
  deleteList: () => {
    return dispatch(deleteList(props.list));
  },
});

const connector = connect(undefined, mapDispatch);

export default connector(DashboardDetailsActionMenu);
