import { List } from '../store/list/types';
import {
  LIST_ID_INBOX,
  LIST_ID_TODAY,
  filterTodayTasks,
  filterOpenTasks,
} from './task';
import { Task } from '../store/task/types';

const todayList: (createdBy: string) => List = (createdBy) => ({
  id: LIST_ID_TODAY,
  createdBy,
  name: 'Today',
  description: 'Tasks due today',
  icon: 'cal.svg',
});

const inboxList: (createdBy: string) => List = (createdBy) => ({
  id: LIST_ID_INBOX,
  createdBy,
  name: 'Inbox',
  description: 'Unsorted tasks',
  icon: 'inbox.svg',
});

export function listSelector(
  userId: string,
  lists: List[],
  tasks: Task[]
): {
  dynamicLists: Array<List & { title: string; subtitle: string }>;
  userLists: Array<List & { title: string; subtitle: string }>;
} {
  const dynamicLists = [];
  const now = new Date();

  // Generate dynamic list TODAY
  const todayTasks = filterTodayTasks(tasks, now).length;
  dynamicLists.push({
    ...todayList(userId),
    title: todayList(userId).name,
    subtitle: `${todayTasks} Task${todayTasks !== 1 ? 's' : ''}`,
  });

  // Generate dynamic list INBOX
  const inboxTasks = filterOpenTasks(
    tasks.filter((task) => task.listId === LIST_ID_INBOX),
    now
  ).length;
  dynamicLists.push({
    ...inboxList(userId),
    title: inboxList(userId).name,
    subtitle: `${inboxTasks} Unsorted Task${inboxTasks !== 1 ? 's' : ''}`,
  });

  return {
    dynamicLists,
    userLists: lists
      .filter((list) => list.id)
      .map((list) => {
        // TODO: Optimize complexity (currently n^2)
        const openTasks = tasks.filter(
          (task) =>
            task.listId === list.id &&
            !task.done &&
            (!task.snoozed || task.snoozed < now)
        ).length;
        return { ...list, title: list.name, subtitle: `${openTasks} Tasks` };
      })
      .sort((a, b) => (a.title > b.title ? 1 : -1)),
  };
}

export function activeListSelector(
  userId: string,
  lists: List[],
  listId: typeof LIST_ID_INBOX | typeof LIST_ID_TODAY | string
): List | undefined {
  if (listId === LIST_ID_TODAY) {
    return todayList(userId);
  } else if (listId === LIST_ID_INBOX) {
    return inboxList(userId);
  } else if (listId) {
    return lists.find((list) => list.id === listId);
  } else {
    return inboxList(userId);
  }
}
