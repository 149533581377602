import { useEffect } from 'react';
import { startTaskSyncing } from '../store/task/actions';
import { store } from '../store';
import { LIST_ID_TODAY } from '../selectors/task';

export const useTaskSyncing = (listId: string) => {
  useEffect(() => {
    if (listId === LIST_ID_TODAY) {
      return () => {};
    }

    process.env.NODE_ENV !== 'test' &&
      console.log('Start syncing tasks', listId);
    const unsubscribe = store.dispatch(startTaskSyncing(listId));

    return () => {
      process.env.NODE_ENV !== 'test' &&
        console.log('Stop syncing tasks', listId);
      unsubscribe();
    };
  }, [listId]);
};
