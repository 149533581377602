import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Fab, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import { makeListAddFormStyles } from './ListAdd.styles';
import { TextField } from '../../forms/TextField';
import { ListIcon } from '../../ui/icons/ListIcon';

export const ListAddNameForm: React.FC<{
  icon: string;
  name: string;
  close?: () => void;
  onChange?: (name: string) => void;
  onSubmit?: (name: string) => void;
  showIcons?: () => void;
}> = (props) => {
  const classes = makeListAddFormStyles();
  const { register, errors, handleSubmit } = useForm<State>({
    validationSchema,
  });

  return (
    <form
      onSubmit={handleSubmit(async (data: State) => {
        props.onSubmit && props.onSubmit(data.name);
      })}
      noValidate
      autoComplete="off"
      style={{ display: 'flex', flexFlow: 'column', height: '100%' }}
    >
      <div className="color-box__back">
        <IconButton
          size="small"
          aria-label="Back"
          edge="start"
          onClick={() => {
            props.close && props.close();
          }}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
      </div>
      <div style={{ display: 'flex', marginBottom: '2.4rem' }}>
        <Button
          aria-label="Edit Icon"
          size="small"
          className={classes.button}
          color="inherit"
          onClick={() => {
            props.showIcons && props.showIcons();
          }}
        >
          <ListIcon filename={props.icon} />
          Edit
        </Button>
        <div className="color-box__header" style={{ margin: 'auto 0.8rem' }}>
          <h2>Add a Icon</h2>
          <span>This helps you to find your list faster</span>
        </div>
      </div>

      <TextField
        value={props.name}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value);
        }}
        name="name"
        label="Enter list name"
        inputRef={register()}
        error={!!errors.name}
        helperText={errors.name && errors.name.message}
        style={{ marginBottom: '4rem' }}
        autoFocus
      />

      <div className="color-box__fab">
        <Fab
          color="secondary"
          aria-label="Submit List"
          style={{ marginLeft: 'auto' }}
          type="submit"
        >
          <ArrowForward />
        </Fab>
      </div>
    </form>
  );
};

interface State {
  name: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required().min(1),
});

export default ListAddNameForm;
