import { makeStyles } from '@material-ui/core/styles';

export const makeTaskItemStyles = makeStyles((theme) => ({
  'task-item__container': {
    background: theme.colorBox,
    '&:hover': {
      background: theme.colorHover,
      'box-shadow':
        theme.palette.type === 'light'
          ? '0px 4px 12px rgba(0, 0, 0, 0.12)'
          : 'none',
    },
    '& .task-item__title': { color: theme.palette.text.primary },
  },
  'task-item__container-snoozed': {
    background: theme.palette.type === 'dark' ? '#1A1A1A' : theme.colorBox,
    '&:hover': {
      background: theme.colorBox,
      'box-shadow':
        theme.palette.type === 'light'
          ? '0px 4px 12px rgba(0, 0, 0, 0.12)'
          : 'none',
    },
    '& .task-item__title': { color: '#888888' },
    '& .task-item__avatar': { opacity: '50%' },
  },
  'task-item__container-done': {
    background: theme.palette.type === 'dark' ? '#1A1A1A' : theme.colorBox,
    '&:hover': {
      background: theme.colorBox,
      'box-shadow':
        theme.palette.type === 'light'
          ? '0px 4px 12px rgba(0, 0, 0, 0.12)'
          : 'none',
    },
    '& .task-item__title': { color: '#888888', textDecoration: 'line-through' },
    '& .task-item__avatar': { opacity: '50%' },
  },
}));
