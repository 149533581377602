import {
  Task,
  TASK_UPDATE_RECEIVED,
  TaskUpdateReceived,
  TASK_REMOVED_RECEIVED,
  TaskRemovedReceived,
  DBTask,
  TaskRefresh,
  TASK_REFRESH,
} from './types';
import firebase from 'firebase';
import { firestore } from '../../firebase';
import { ThunkResult } from '..';
import { snackbarError } from '../system/actions';
import { LIST_ID_TODAY, LIST_ID_INBOX } from '../../selectors/task';
import { refreshTasks } from './subscribers';

export function addTask(task: Omit<Task, 'id'>): ThunkResult<void> {
  return async (dispatch, getState) => {
    const createdBy = getState().auth.user?.uid;
    const path =
      task.listId !== LIST_ID_TODAY && task.listId !== LIST_ID_INBOX
        ? `lists/${task.listId}/tasks`
        : `inbox/${createdBy}/tasks`;

    try {
      const { listId, ...dbtask } = { ...task, createdBy };

      if (task.listId === LIST_ID_TODAY) {
        dbtask.snoozed = new Date();
      }

      await firestore.collection(path).add(dbtask);
    } catch (error) {
      dispatch(snackbarError(error.message));
    }
  };
}

function _updateTask(
  { id, listId }: { id: string; listId: string },
  updates: Partial<DBTask> | { snoozed: firebase.firestore.FieldValue }
): ThunkResult<void> {
  return async (dispatch, getState) => {
    const path =
      listId !== LIST_ID_INBOX
        ? `lists/${listId}/tasks`
        : `inbox/${getState().auth.user?.uid}/tasks`;
    try {
      await firestore.collection(path).doc(id).update(updates);
    } catch (error) {
      dispatch(snackbarError(error.message));
    }
  };
}

export function updateTask(
  { id, listId }: { id: string; listId: string },
  updates: Partial<{ title: string; done: boolean }>
): ThunkResult<void> {
  return _updateTask({ id, listId }, updates);
}

export function snoozeTask(
  { id, listId }: { id: string; listId: string },
  until: Date
): ThunkResult<void> {
  return _updateTask(
    { id, listId },
    { snoozed: firebase.firestore.Timestamp.fromDate(until), done: false }
  );
}

export function unsnoozeTask({
  id,
  listId,
}: {
  id: string;
  listId: string;
}): ThunkResult<void> {
  return _updateTask(
    { id, listId },
    { snoozed: firebase.firestore.FieldValue.delete(), done: false }
  );
}

export function deleteTask(task: {
  id: string;
  listId: string;
}): ThunkResult<Promise<boolean>> {
  return async (dispatch, getState) => {
    try {
      const path =
        task.listId !== LIST_ID_INBOX
          ? `lists/${task.listId}/tasks`
          : `inbox/${getState().auth.user?.uid}/tasks`;
      await firestore.collection(path).doc(task.id).delete();
      return true;
    } catch (error) {
      dispatch(snackbarError(error.message));
      return false;
    }
  };
}

export function taskUpdateReceived(task: Task): TaskUpdateReceived {
  return {
    type: TASK_UPDATE_RECEIVED,
    task,
  };
}

export function taskRemovedReceived(taskId: string): TaskRemovedReceived {
  return {
    type: TASK_REMOVED_RECEIVED,
    taskId,
  };
}

export function taskRefresh(): TaskRefresh {
  return {
    type: TASK_REFRESH,
  };
}

export function startTaskSyncing(listId: string): ThunkResult<() => void> {
  if (listId === LIST_ID_TODAY) {
    return (dispatch, getState) => () => {};
  }

  return (dispatch, getState) => {
    const createdBy = getState().auth.user?.uid;
    const path =
      listId !== LIST_ID_INBOX
        ? `lists/${listId}/tasks`
        : `inbox/${createdBy}/tasks`;

    const unsubscribeRefreshTasks = refreshTasks();

    const unsubscribe = firestore.collection(path).onSnapshot(
      function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === 'removed') {
            dispatch(taskRemovedReceived(change.doc.id));
          } else {
            const dbTask = change.doc.data() as DBTask;
            const task: Task = {
              id: change.doc.id,
              listId: listId,
              title: dbTask.title,
              done: !!dbTask.done,
            };

            if (dbTask.snoozed) {
              task.snoozed = dbTask.snoozed.toDate();
            }

            dispatch(taskUpdateReceived(task));

            if (task.snoozed) {
              setTimeout(() => {
                dispatch(taskRefresh());
              }, task.snoozed.getTime() - new Date().getTime());
            }
          }
        });
      },
      function (error) {
        console.log(`failed syngin tasks for list ${listId}`, error);
        dispatch(snackbarError(error.message));
      }
    );

    // Here you could dispatch an action that sets a syncing state:
    // dispatch(taskSyncingStarted());

    return () => {
      unsubscribe();
      unsubscribeRefreshTasks();
      // Here you could dispatch an action that unsets a syncing state:
      // dispatch(taskSyncingStopped());
    };
  };
}
