import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import SignupPage from '../onboarding/signup/SignupPage';
import SignupEmailPage from '../onboarding/signup/SignupEmailPage';

export const OnboardingPage: React.FC = () => {
  return (
    <Switch>
      <Route path="/signup/email">
        <SignupEmailPage />
      </Route>
      <Route path="/signup" exact={true}>
        <SignupPage />
      </Route>
      <Route>
        {/* TODO: Proper 404 page*/}
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default OnboardingPage;
