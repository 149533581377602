import React from 'react';
import './TaskDetailsModal.css';
import { makeTaskDetailsStyles } from './TaskDetailsModal.styles';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import { Backdrop, IconButton, Modal as MUIModal } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import { ColorBox } from '../../ui/ColorBox';
import { AvatarMale } from '../../ui/icons';
import { DYNAMIC_LIST_IDS } from '../../../selectors/task';
import { ReactComponent as AddFriendIcon } from '../../ui/icons/icons/system/task/add_friend.svg';
import { snackbarInfo } from '../../../store/system/actions';
import { List } from '../../../store/list/types';
import TaskDetailsActionMenu from './TaskDetailsActionMenu';

export const TaskDetailsModal: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const classes = makeTaskDetailsStyles();
  const history = useHistory();

  return (
    <MUIModal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
      onClose={props.close}
      style={{ display: 'flex' }}
      open={props.open}
      className="task-details"
    >
      <Fade in={props.open} timeout={300}>
        <div className={classes.paper}>
          <ColorBox width="100%" height="100%">
            {props.task !== undefined ? (
              <React.Fragment>
                <div className="task-details__header">
                  <div className="task-details__header__close">
                    <IconButton
                      size="small"
                      color="inherit"
                      aria-label="Notifications"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <Close />
                    </IconButton>
                  </div>
                  <div className="task-details__header__actions">
                    {!DYNAMIC_LIST_IDS.includes(props.task.listId) && (
                      <AvatarMale className="avatar" />
                    )}
                    <IconButton
                      size="small"
                      color="inherit"
                      aria-label="Add Friends"
                      onClick={props.invite}
                    >
                      <AddFriendIcon />
                    </IconButton>
                    <TaskDetailsActionMenu
                      task={props.task}
                      openedIn={props.openedIn}
                    />
                  </div>
                </div>
                <div className="task-contents">
                  <div className="task-contents__due">Due Today</div>
                  <h1 className="task-contents__title">{props.task.title}</h1>
                </div>
              </React.Fragment>
            ) : (
              <div>TODO: Task not found</div>
            )}
          </ColorBox>
        </div>
      </Fade>
    </MUIModal>
  );
};

const mapStateToProps = (
  state: AppState,
  props: {
    taskId: string;
    openedIn: List;
    open: boolean;
    close?: () => void;
  }
) => ({
  task: state.tasks.find((task) => task.id === props.taskId),
  taskId: props.taskId,
  openedIn: props.openedIn,
  open: props.open,
  close: props.close,
});

const mapDispatch = (dispatch: AppDispatch) => ({
  invite: () => {
    dispatch(snackbarInfo('Feature coming soon...'));
  },
});

const connector = connect(mapStateToProps, mapDispatch);

export default connector(TaskDetailsModal);
