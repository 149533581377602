import React from 'react';
import Lottie from 'react-lottie';
import animationData from './lottie/wando_logo_animation.json';

const LogoAnimated: React.FC<{ onComplete?: () => void }> = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default LogoAnimated;
