import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from '@material-ui/core';
import AndroidIcon from '@material-ui/icons/Android';
import { makeNavigationDrawerStyles } from './NavigationDrawer.styles';
import {
  ProfileIcon,
  SettingsIcon,
  LogoutIcon,
  ImprintIcon,
  AvatarMale,
  AutoThemeIcon,
  LightThemeIcon,
  DarkThemeIcon,
} from '../ui/icons';
import { startLogout } from '../../store/auth/actions';
import { AppState, AppDispatch } from '../../store';
import { switchTheme } from '../../store/theme/actions';

export const NavigationDrawer: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const classes = makeNavigationDrawerStyles();
  const history = useHistory();

  return (
    <Drawer anchor="left" open={props.open} onClose={props.onClose}>
      <div className={classes.header}>
        <div className={classes.header__icons}>
          <AvatarMale className="avatar" />
          <div className={classes.header__theme}>
            <IconButton
              aria-label="theme"
              color="inherit"
              onClick={() => {
                props.switchTheme(
                  props.themeType === 'auto'
                    ? 'dark'
                    : props.themeType === 'dark'
                    ? 'light'
                    : 'auto'
                );
              }}
            >
              {props.themeType === 'dark' ? (
                <DarkThemeIcon />
              ) : props.themeType === 'light' ? (
                <LightThemeIcon />
              ) : (
                <AutoThemeIcon />
              )}
            </IconButton>
          </div>
        </div>
        <div className={classes.header__name}>{props.name}</div>
        <div className={classes.header__email}>{props.email}</div>
      </div>

      <List className={classes.list}>
        <ListItem
          button
          className={classes.item}
          onClick={() => {
            history.push('/account');
          }}
        >
          <ListItemIcon>
            <ProfileIcon />
          </ListItemIcon>
          <ListItemText primary="My Account" />
        </ListItem>
        <ListItem
          button
          className={classes.item}
          onClick={props.onClose}
          disabled
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>

      <Divider className={classes.divider} />

      <List className={classes.list}>
        <ListItem
          button
          className={classes.item}
          onClick={(event) => {
            props.startLogout();
            props.onClose(event);
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
        <ListItem
          button
          className={classes.item}
          onClick={props.onClose}
          disabled
        >
          <ListItemIcon>
            <ImprintIcon />
          </ListItemIcon>
          <ListItemText primary="Legal" />
        </ListItem>
        <ListItem
          button
          className={classes.item}
          component="a"
          href="https://wando.app/android"
          target="_blank"
        >
          <ListItemIcon>
            <AndroidIcon />
          </ListItemIcon>
          <ListItemText primary="Wando Android App" />
        </ListItem>
      </List>
    </Drawer>
  );
};

const mapStateToProps = (
  state: AppState,
  props: {
    open: boolean;
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  }
) => {
  return {
    ...props,
    name: state.auth.user?.displayName || '',
    email: state.auth.user?.email || '',
    themeType: state.theme.themeType,
  };
};

const mapDispatch = (dispatch: AppDispatch) => ({
  startLogout: () => {
    dispatch(startLogout());
  },
  switchTheme: (themeType: 'dark' | 'light' | 'auto') =>
    dispatch(switchTheme(themeType)),
});

const connector = connect(mapStateToProps, mapDispatch);

export default connector(NavigationDrawer);
