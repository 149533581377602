import { Task } from '../store/task/types';

export const LIST_ID_TODAY = 'TODAY';
export const LIST_ID_INBOX = 'INBOX';
export const DYNAMIC_LIST_IDS = [LIST_ID_INBOX, LIST_ID_TODAY];

export function taskSelector(
  tasks: Task[],
  listId: typeof LIST_ID_INBOX | typeof LIST_ID_TODAY | string
): { open: Task[]; snoozed: Task[]; done: Task[] } {
  const now = new Date();
  if (listId === LIST_ID_TODAY) {
    return {
      open: filterTodayTasks(tasks, now).sort((a, b) =>
        a.title > b.title ? 1 : -1
      ),
      snoozed: [],
      done: [],
    };
  } else {
    const tasksInList = tasks.filter((task) => task.listId === listId);
    return {
      open: filterOpenTasks(tasksInList, now).sort((a, b) =>
        a.title > b.title ? 1 : -1
      ),
      snoozed: filterSnoozedTasks(tasksInList, now).sort((a, b) =>
        a.title > b.title ? 1 : -1
      ),
      done: filterDoneTasks(tasksInList).sort((a, b) =>
        a.title > b.title ? 1 : -1
      ),
    };
  }
}

export function filterTodayTasks(tasks: Task[], now: Date): Task[] {
  return tasks.filter(
    (task) => !task.done && task.snoozed && task.snoozed <= now
  );
}

export function filterOpenTasks(tasks: Task[], now: Date): Task[] {
  return tasks.filter(
    (task) => !task.done && (!task.snoozed || task.snoozed <= now)
  );
}

function filterSnoozedTasks(tasks: Task[], now: Date): Task[] {
  return tasks.filter(
    (task) => !task.done && task.snoozed && task.snoozed > now
  );
}

function filterDoneTasks(tasks: Task[]): Task[] {
  return tasks.filter((task) => task.done);
}

export function nextSnoozedTaskSelector(tasks: Task[]): Task | undefined {
  const snoozedTasks = filterSnoozedTasks(tasks, new Date());
  snoozedTasks.sort((a, b) => a.snoozed!.getTime() - b.snoozed!.getTime());
  return snoozedTasks.length > 0 ? snoozedTasks[0] : undefined;
}
