import { ThemeState, SWITCH_THEME, ThemeActionTypes } from './types';

const initialState: ThemeState = {
  themeType:
    (localStorage.getItem('themeType') as 'light' | 'dark' | 'auto') || 'auto',
};

export function themeReducer(
  state = initialState,
  action: ThemeActionTypes
): ThemeState {
  switch (action.type) {
    case SWITCH_THEME:
      return { themeType: action.themeType };
    default:
      return state;
  }
}
