import React from 'react';

import Modal from '../../ui/Modal';
import ListAddController from './ListAddController';
import { addList } from '../../../store/list/actions';

export const ListAddModal: React.FC<{
  open: boolean;
  close?: () => void;
}> = (props) => {
  return (
    <Modal onClose={props.close} open={props.open} width="40rem" height="48rem">
      <ListAddController close={props.close} onSubmit={addList} />
    </Modal>
  );
};

export default ListAddModal;
