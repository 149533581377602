import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import './TaskItem.css';
import { makeTaskItemStyles } from './TaskItem.styles';
import { SubtasksIcon, AttachmentIcon, AvatarMale } from '../../ui/icons';
import { AppDispatch } from '../../../store';
import { updateTask, unsnoozeTask } from '../../../store/task/actions';
import { AnimatedCheckbox } from '../../forms/AnimatedCheckbox';
import SnoozeButton from './SnoozeButton';
import { UnSnoozeIcon } from '../../ui/icons';
import { List } from '../../../store/list/types';

export interface TaskItemProps {
  id: string;
  listId: string;
  title: string;
  done: boolean;
  snoozed?: boolean;
  avatar: string;
  subtasks: boolean;
  attachments: boolean;
}

export const TaskItem: React.FC<ConnectedProps<typeof connector>> = (props) => {
  const history = useHistory();
  const classes = makeTaskItemStyles();

  return (
    <ButtonBase
      focusRipple
      component="a"
      className="task-item"
      onClick={() => {
        history.push(`/lists/${props.openedIn.id}/tasks/${props.id}`);
      }}
    >
      <div
        className={`task-item__container ${
          !props.done && !props.snoozed
            ? classes['task-item__container']
            : props.snoozed
            ? classes['task-item__container-snoozed']
            : classes['task-item__container-done']
        }`}
      >
        <div className="task-item__checkbox">
          <AnimatedCheckbox
            checked={props.done}
            color="secondary"
            inputProps={{ 'aria-label': 'Done' }}
            focusRipple
            onChange={(event, checked) => {
              props.setChecked(checked);
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className="task-item__title">
          <div className="task-item__text">{props.title}</div>
          {(props.attachments || props.subtasks) && (
            <div className="task-item__icons">
              {props.subtasks && <SubtasksIcon />}
              {props.attachments && <AttachmentIcon />}
            </div>
          )}
        </div>
        {!props.done && (
          <div className="task-item_snooze">
            {props.snoozed ? (
              <IconButton
                color="inherit"
                aria-label="Un-Snooze"
                focusRipple
                onClick={(e) => {
                  e.stopPropagation();
                  props.unSnooze();
                }}
                onMouseDown={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
              >
                <UnSnoozeIcon />
              </IconButton>
            ) : (
              <SnoozeButton
                taskId={props.id}
                listId={props.listId}
                openMenu={props.openMenu}
              />
            )}
          </div>
        )}
        <div className="task-item__avatar">
          <AvatarMale className="avatar" />
        </div>
      </div>
    </ButtonBase>
  );
};

const mapDispatch = (
  dispatch: AppDispatch,
  props: TaskItemProps & {
    openMenu: (
      taskId: string,
      listId: string,
      event: React.MouseEvent<HTMLButtonElement>
    ) => void;
    openedIn: List;
  }
) => ({
  setChecked: (done: boolean) => {
    dispatch(updateTask({ ...props }, { done }));
  },
  unSnooze: () => {
    dispatch(unsnoozeTask({ ...props }));
  },
  ...props,
});

const connector = connect(undefined, mapDispatch);

export default connector(TaskItem);
