import firebase from 'firebase';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
const firestore = firebase.firestore();
firestore
  .enablePersistence()
  .then(() => {
    process.env.NODE_ENV !== 'test' &&
      console.log('Offline Persistence activated');
  })
  .catch(function (err) {
    // TODO: Handle error
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence.
    }
    process.env.NODE_ENV !== 'test' &&
      console.log('Failed to activate Offline Persistence:', err);
  });
// Subsequent queries will use persistence, if it was enabled successfully

export { firebase as default, firestore, analytics };
