import React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { analytics } from '../firebase';

const FirebaseAnalytics: React.FC = () => {
  let location = useLocation();
  useEffect(() => {
    const path = location.pathname + location.search;
    analytics.setCurrentScreen(path);
    analytics.logEvent('page_view', { page_path: path });
  }, [location]);
  return null;
};

export default FirebaseAnalytics;
