import { AuthState, LOGGED_IN, LOGGED_OUT, AuthActionTypes } from './types';

const initialState: AuthState = {
  user: null,
  authStateDetermined: false,
};

export function authReducer(
  state = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case LOGGED_IN:
      return { user: action.payload, authStateDetermined: true };
    case LOGGED_OUT:
      return { user: null, authStateDetermined: true };
    default:
      return state;
  }
}
