import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../store';
import './LoadingSpinner.css';
import { makeLoadingSpinnerstyles } from './LoadingSpinner.styles';
import LogoAnimated from './icons/LogoAnimated';

const mapStateToProps = (state: AppState) => ({
  show: state.system.operationsRunning > 0,
});
const connector = connect(mapStateToProps, {});

export const LoadingSpinner: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const classes = makeLoadingSpinnerstyles();

  return props.show ? (
    <div className={`loading-spinner ${classes['loading-spinner']}`}>
      <div className="loading-spinner__inner">
        <LogoAnimated />
      </div>
    </div>
  ) : null;
};

export default connector(LoadingSpinner);
