import React from 'react';
import { Modal as MUIModal, Backdrop } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { makeModalStyles } from './Modal.styles';
import { ColorBox } from './ColorBox';

export const Modal: React.FC<{
  onClose?: () => void;
  open: boolean;
  width?: string;
  height?: string;
  onBack?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = (props) => {
  const classes = makeModalStyles();

  return (
    <MUIModal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
      onClose={props.onClose}
      style={{ display: 'flex' }}
      open={props.open}
    >
      <Fade in={props.open} timeout={300}>
        <div className={classes.paper}>
          <ColorBox {...props}>{props.children}</ColorBox>
        </div>
      </Fade>
    </MUIModal>
  );
};

export default Modal;
