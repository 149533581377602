import 'date-fns';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker as MUIDatePicker,
} from '@material-ui/pickers';
import { makeDatePickerDialogStyles } from './styles';

export const DatePicker: React.FC<{
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAccept: (
    event: React.MouseEvent<HTMLButtonElement>,
    date: Date | null
  ) => void;
}> = (props) => {
  const classes = makeDatePickerDialogStyles();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ classes: { root: classes.dialog } }}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onFocus={(e) => e.stopPropagation()}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MUIDatePicker
          disableToolbar
          variant="static"
          format="dd.MM.yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date picker inline"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>

      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            props.onAccept(e, selectedDate);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
