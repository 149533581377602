import './style.css';
import { ReactComponent as LogoIcon } from './icons/logo_wando.svg';
import { ReactComponent as AvatarMale } from './icons/placeholder_avatar_male.svg';
import { ReactComponent as AttachmentIcon } from './icons/system/task/attachment.svg';
import { ReactComponent as SubtasksIcon } from './icons/system/task/subtasks.svg';
import { ReactComponent as CheckboxUncheckedIcon } from './icons/system/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from './icons/system/checkbox_completed.svg';
import { ReactComponent as VisibilityIcon } from './icons/system//visibility.svg';
import { ReactComponent as VisibilityOffIcon } from './icons/system//visibility_off.svg';
import { ReactComponent as DefaultCategoryIcon } from './icons/system/category/default.svg';
import { ReactComponent as InboxIcon } from './icons/system/inbox.svg';
import { ReactComponent as AlertIcon } from './icons/system/alert.svg';
import { ReactComponent as EditIcon } from './icons/system/edit.svg';
import { ReactComponent as TrashIcon } from './icons/system/trash.svg';
import { ReactComponent as SnoozeIcon } from './icons/system/snooze.svg';
import { ReactComponent as UnSnoozeIcon } from './icons/system/unsnooze.svg';
import { ReactComponent as OneHourIcon } from './icons/system/snooze/1hour.svg';
import { ReactComponent as DateIcon } from './icons/system/snooze/date.svg';
import { ReactComponent as NextWeekIcon } from './icons/system/snooze/nextweek.svg';
import { ReactComponent as TomorrowIcon } from './icons/system/snooze/tomorrow.svg';
import { ReactComponent as MenuIcon } from './icons/system/menu.svg';
import { ReactComponent as ProfileIcon } from './icons/system/profile.svg';
import { ReactComponent as SettingsIcon } from './icons/system/settings.svg';
import { ReactComponent as LogoutIcon } from './icons/system/logout.svg';
import { ReactComponent as ImprintIcon } from './icons/system/imprint.svg';
import { ReactComponent as LightThemeIcon } from './icons/system/light.svg';
import { ReactComponent as DarkThemeIcon } from './icons/system/dark.svg';
import { ReactComponent as AutoThemeIcon } from './icons/system/theme_auto.svg';
import { ReactComponent as AddFriendIcon } from './icons/system/task/add_friend.svg';

LogoIcon.defaultProps = { className: 'icon' };
CheckboxUncheckedIcon.defaultProps = { className: 'stroke-icon' };
CheckboxCheckedIcon.defaultProps = { className: 'stroke-icon' };
VisibilityIcon.defaultProps = { className: 'icon' };
VisibilityOffIcon.defaultProps = { className: 'icon' };
AttachmentIcon.defaultProps = { className: 'icon' };
SubtasksIcon.defaultProps = { className: 'icon' };
DefaultCategoryIcon.defaultProps = { className: 'icon' };
InboxIcon.defaultProps = { className: 'icon' };
AlertIcon.defaultProps = { className: 'icon' };
EditIcon.defaultProps = { className: 'icon' };
TrashIcon.defaultProps = { className: 'icon' };
SnoozeIcon.defaultProps = { className: 'icon' };
UnSnoozeIcon.defaultProps = { className: 'icon' };
OneHourIcon.defaultProps = { className: 'icon' };
DateIcon.defaultProps = { className: 'icon' };
NextWeekIcon.defaultProps = { className: 'icon' };
TomorrowIcon.defaultProps = { className: 'icon' };
MenuIcon.defaultProps = { className: 'icon' };
ProfileIcon.defaultProps = { className: 'icon' };
SettingsIcon.defaultProps = { className: 'icon' };
LogoutIcon.defaultProps = { className: 'icon' };
ImprintIcon.defaultProps = { className: 'icon' };
LightThemeIcon.defaultProps = { className: 'icon' };
DarkThemeIcon.defaultProps = { className: 'icon' };
AutoThemeIcon.defaultProps = { className: 'icon' };
AddFriendIcon.defaultProps = { className: 'icon' };

export {
  LogoIcon,
  AvatarMale,
  CheckboxUncheckedIcon,
  CheckboxCheckedIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  AttachmentIcon,
  SubtasksIcon,
  DefaultCategoryIcon,
  InboxIcon,
  AlertIcon,
  EditIcon,
  TrashIcon,
  SnoozeIcon,
  UnSnoozeIcon,
  OneHourIcon,
  DateIcon,
  NextWeekIcon,
  TomorrowIcon,
  MenuIcon,
  ProfileIcon,
  SettingsIcon,
  LogoutIcon,
  ImprintIcon,
  LightThemeIcon,
  DarkThemeIcon,
  AutoThemeIcon,
  AddFriendIcon,
};
