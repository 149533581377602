import { makeStyles } from '@material-ui/core/styles';

export const makeColorBoxStyles = (width: string, height: string) => {
  return makeStyles((theme) => ({
    'color-box': {
      width,
      'min-width': width,
      height,
      'min-height': height,
      background: theme.colorBox,
    },
  }))();
};
