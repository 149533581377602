import { makeStyles } from '@material-ui/core/styles';

export const makeConsecutiveSnackbarsStyles = makeStyles((theme) => ({
  snackbar: {
    background: theme.colorSnackbar,
    color: theme.palette.primary.dark,
    'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.08)',
    'border-radius': '8px',
  },
}));
