import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ColorBox } from '../../ui/ColorBox';
import { MailField } from '../../forms/MailField';
import { PasswordField } from '../../forms/PasswordField';
import { startEmailPasswordSignUp } from '../../../store/auth/actions';
import { AppDispatch } from '../../../store';

export const SignupEmailPage: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const history = useHistory();

  const { register, errors, handleSubmit } = useForm<State>({
    validationSchema,
  });

  const onSubmit = (data: State) => {
    props.startEmailPasswordSignUp(data.email, data.password);
  };

  return (
    <div className="center-box">
      <div style={{ padding: '4.8rem' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <ColorBox
            submitButton={true}
            onBack={() => {
              history.goBack();
            }}
          >
            <div className="color-box__header">
              <h2>Create a new account</h2>
              <span>By signing up you agree to our Terms of Service</span>
            </div>

            <MailField
              name="email"
              inputRef={register()}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              style={{ marginBottom: '4rem' }}
            />

            <PasswordField
              name="password"
              inputRef={register()}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              style={{ marginBottom: '1.6rem' }}
            />
          </ColorBox>
        </form>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch: AppDispatch) => ({
  startEmailPasswordSignUp: (email: string, password: string) => {
    dispatch(startEmailPasswordSignUp(email, password));
  },
});

const connector = connect(undefined, mapDispatch);

interface State {
  email: string;
  password: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required().min(8),
});

export default connector(SignupEmailPage);
