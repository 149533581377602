import { LoggedOutAction } from '../auth/types';
import firebase from 'firebase';

// Entity Interfaces
export interface Task {
  id: string;
  listId: string;
  title: string;
  done: boolean;
  snoozed?: Date;
  refresh?: Date;
}
export interface DBTask {
  title: string;
  done?: boolean;
  snoozed?: firebase.firestore.Timestamp;
}

// State Interfaces
export type TaskState = Array<Task>;

// Describing the different ACTION NAMES available
export const TASK_UPDATE_RECEIVED = 'TASK_UPDATE_RECEIVED';
export const TASK_REMOVED_RECEIVED = 'TASK_REMOVED_RECEIVED';
export const TASK_REFRESH = 'TASK_REFRESH';

// Action Interfaces
export interface TaskUpdateReceived {
  type: typeof TASK_UPDATE_RECEIVED;
  task: Task;
}
export interface TaskRemovedReceived {
  type: typeof TASK_REMOVED_RECEIVED;
  taskId: string;
}
export interface TaskRefresh {
  type: typeof TASK_REFRESH;
}

// Action Union
export type TaskActionTypes =
  | TaskUpdateReceived
  | TaskRemovedReceived
  | TaskRefresh
  | LoggedOutAction;
