import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Modal from '../../ui/Modal';
import ListAddController from './ListAddController';
import { updateList } from '../../../store/list/actions';
import { List } from '../../../store/list/types';
import { AppState } from '../../../store';

export const ListEditModal: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  return (
    <Modal onClose={props.close} open={props.open} width="40rem" height="48rem">
      <ListAddController
        name={props.list?.name}
        icon={props.list?.icon}
        close={props.close}
        onSubmit={(updates: Omit<Omit<List, 'id'>, 'createdBy'>) => {
          return updateList({ id: props.listId }, updates);
        }}
      />
    </Modal>
  );
};

const mapStateToProps = (
  state: AppState,
  props: {
    listId: string;
    open: boolean;
    close?: () => void;
  }
) => ({
  list: state.lists.find((list) => list.id === props.listId),
  listId: props.listId,
  open: props.open,
  close: props.close,
});

const connector = connect(mapStateToProps, {});

export default connector(ListEditModal);
