import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import './LoginForm.css';
import { ColorBox } from '../../ui/ColorBox';
import { PasswordField } from '../../forms/PasswordField';
import { MailField } from '../../forms/MailField';
import { makeButtonStyles } from '../../forms/styles';
import { AppDispatch } from '../../../store';
import { startEmailPasswordSignIn } from '../../../store/auth/actions';

export const LoginForm: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const { register, errors, handleSubmit } = useForm<State>({
    validationSchema,
  });

  const onSubmit = (data: State) => {
    props.startEmailPasswordSignIn(data.email, data.password);
  };

  return (
    <div className="login-form">
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <ColorBox height="38rem">
          <div className="color-box__header">
            <h2>Already have an account?</h2>
            <span>Sign in with E-Mail &amp; Password</span>
          </div>

          <MailField
            name="email"
            inputRef={register()}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
            style={{ marginBottom: '4rem' }}
          />

          <PasswordField
            name="password"
            inputRef={register()}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            style={{ marginBottom: '1.6rem' }}
          />

          <div className="login-form__password-reset-link">
            <Link to="/reset-password">Forgot password?</Link>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth
              className={makeButtonStyles().button}
            >
              Login
            </Button>
          </div>
        </ColorBox>
      </form>
    </div>
  );
};

const mapDispatch = (dispatch: AppDispatch) => ({
  startEmailPasswordSignIn: (email: string, password: string) => {
    dispatch(startEmailPasswordSignIn(email, password));
  },
});

const connector = connect(undefined, mapDispatch);

interface State {
  email: string;
  password: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

export default connector(LoginForm);
