// See https://material-ui.com/components/snackbars/#consecutive-snackbars
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { makeConsecutiveSnackbarsStyles } from './ConsecutiveSnackbars.styles';
import { AppState } from '../../store';
import { snackbarClear } from '../../store/system/actions';
import { SnackbarMessage } from '../../store/system/types';

export const ConsecutiveSnackbars: React.FC = (props) => {
  const classes = makeConsecutiveSnackbarsStyles();
  const dispatch = useDispatch();
  const snackPack: SnackbarMessage[] = useSelector(
    (state: AppState) => state.system.messages
  );

  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    SnackbarMessage | undefined
  >(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      //setSnackPack((prev) => prev.slice(1));
      dispatch(snackbarClear(snackPack[0].key));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open, dispatch]);

  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Slide}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        onExited={handleExited}
        message={messageInfo ? messageInfo.message : undefined}
        ContentProps={{ classes: { root: classes.snackbar } }}
      />
    </div>
  );
};

export default ConsecutiveSnackbars;
