// State Interfaces
export interface ThemeState {
  themeType: 'light' | 'dark' | 'auto';
}

// Describing the different ACTION NAMES available
export const SWITCH_THEME = 'SWITCH_THEME';

// Action Interfaces
export interface SwitchThemeAction {
  type: typeof SWITCH_THEME;
  themeType: 'light' | 'dark' | 'auto';
}

// Action Union
export type ThemeActionTypes = SwitchThemeAction;
