import weather from 'openweather-apis';

export type OpenWeatherIcon =
  | '01d'
  | '01n'
  | '02d'
  | '02n'
  | '03d'
  | '03n'
  | '04d'
  | '04n'
  | '09d'
  | '09n'
  | '10d'
  | '10n'
  | '11d'
  | '11n'
  | '13d'
  | '13n'
  | '50d'
  | '50n';

export async function getWeatherIcon(): Promise<OpenWeatherIcon | undefined> {
  try {
    const position: Position = await new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    weather.setUnits('metric');
    weather.setAPPID(process.env.REACT_APP_OPENWEATHER_API_KEY);
    weather.setCoordinate(position.coords.latitude, position.coords.longitude);

    const icon: OpenWeatherIcon = await new Promise(function (resolve, reject) {
      weather.getAllWeather(function (
        err: any,
        result: {
          weather: Array<{
            id: number;
            main: string;
            description: string;
            icon: OpenWeatherIcon;
          }>;
        }
      ) {
        if (err) {
          reject(err);
        } else {
          result.weather && result.weather.length
            ? resolve(result.weather[0].icon)
            : reject(new Error('No weather data available'));
        }
      });
    });

    return icon;
  } catch (error) {
    console.warn('Error fetching weather', error);
  }
}

export default weather;
