import { makeStyles } from '@material-ui/core/styles';

export const makeListItemStyles = makeStyles((theme) => ({
  'list-item': {
    background: theme.colorBox,
    '&:hover': {
      background: theme.colorHoverOnBox,
      '& .list-item__icon svg': { color: theme.palette.text.primary },
      '& .icon-cal__date': { color: theme.palette.text.primary },
    },
    '&.active': {
      background: theme.colorHoverOnBox,
      '& .list-item__icon svg': { color: theme.palette.text.primary },
      '& .icon-cal__date': { color: theme.palette.text.primary },
    },
  },
  'list-item__title': { color: theme.palette.text.primary },
}));
