import React from 'react';
import './CalIcon.css';
import { ReactComponent as Icon } from './icons/system/task/cal.svg';

export const CalIcon: React.FC = () => {
  return (
    <div className="icon icon-cal">
      <Icon xmlns="http://www.w3.org/2000/svg"></Icon>
      <span className="icon-cal__date">{new Date().getDate()}</span>
    </div>
  );
};
