import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { CheckboxUncheckedIcon, CheckboxCheckedIcon } from '../ui/icons';
import CircleCheckedAnimated from '../ui/icons/CircleCheckedAnimated';

export const AnimatedCheckbox: React.FC<CheckboxProps> = (props) => {
  const [checked, setChecked] = React.useState(props.checked);
  const [animating, setAnimating] = React.useState<
    React.ChangeEvent<HTMLInputElement> | false
  >(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Animating checkbox and delaying onChange()
      setAnimating(event);
      setChecked(true);
    } else {
      // Immediately calling onChange()
      setChecked(false);
      props.onChange && props.onChange(event, false);
    }
  };

  return (
    <Checkbox
      {...props}
      checked={checked}
      disabled={animating !== false || props.disabled}
      onChange={handleChange}
      color="default"
      icon={<CheckboxUncheckedIcon />}
      checkedIcon={
        animating ? (
          <CircleCheckedAnimated
            onComplete={() => {
              setAnimating(false);
              props.onChange && props.onChange(animating, true);
            }}
          />
        ) : (
          <CheckboxCheckedIcon />
        )
      }
    />
  );
};
