import { useEffect } from 'react';
import firebase from '../firebase';
import { loggedIn, loggedOut } from '../store/auth/actions';
import { store } from '../store';

export const useAuthentication = () => {
  useEffect(() => {
    process.env.NODE_ENV !== 'test' &&
      console.log('Start listening for authentication changes');

    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        process.env.NODE_ENV !== 'test' &&
          console.log('Authentication change: user is authenticated', user);
        store.dispatch(loggedIn(user));
      } else {
        console.log('Authentication change: user is not authenticated');
        store.dispatch(loggedOut());
      }
    });

    return () => {
      process.env.NODE_ENV !== 'test' &&
        console.log('Stop listening for authentication changes');
      unsubscribe();
    };
  }, []);
};
