import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeButtonStyles } from '../../forms/styles';
import ListAddModal from './ListAddModal';

export const ListAddButton: React.FC = () => {
  const classes = makeButtonStyles();
  const [showsListAddModal, setShowsListAddModal] = useState(false);
  const showListAddModal = () => {
    setShowsListAddModal(true);
  };
  const closeListAddModal = () => {
    setShowsListAddModal(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        size="large"
        fullWidth
        className={classes.button}
        onClick={showListAddModal}
      >
        Add List
      </Button>

      <ListAddModal open={showsListAddModal} close={closeListAddModal} />
    </div>
  );
};

export default ListAddButton;
