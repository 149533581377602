import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addList } from '../../../store/list/actions';
import { AppDispatch } from '../../../store';
import ListAddNameForm from './ListAddNameForm';
import ListAddIconSelector from './ListAddIconSelector';

export const ListAddController: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const history = useHistory();
  const [showsIcons, showIcons] = useState(false);
  const [name, setName] = useState<string>(props.name || '');
  const [icon, setIcon] = useState<string>(props.icon || 'defaut.svg');

  const close = () => {
    props.close && props.close();
  };

  const addList = (name: string) => {
    close();
    props
      .addList({
        name,
        description: '',
        icon,
      })
      .then((listId) => {
        listId && history.push(`/lists/${listId}`);
      });
  };

  return showsIcons ? (
    <ListAddIconSelector
      close={() => {
        showIcons(false);
      }}
      onChange={(icon) => {
        setIcon(icon);
        showIcons(false);
      }}
      icon={icon}
    />
  ) : (
    <ListAddNameForm
      close={props.close}
      onChange={setName}
      onSubmit={addList}
      icon={icon}
      name={name}
      showIcons={() => {
        showIcons(true);
      }}
    />
  );
};

interface State {
  name: string;
  icon: string;
}

const mapDispatch = (
  dispatch: AppDispatch,
  props: {
    onSubmit: typeof addList;
    close?: () => void;
    name?: string;
    icon?: string;
  }
) => ({
  addList: (list: { name: string; description: string; icon: string }) => {
    return dispatch(props.onSubmit(list));
  },
  ...props,
});

const connector = connect(undefined, mapDispatch);

export default connector(ListAddController);
