import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import './ListItem.css';
import { makeListItemStyles } from './ListItem.styles';
import { ListIcon } from '../../ui/icons/ListIcon';
import { useTaskSyncing } from '../../../hooks/useTaskSyncing';

export interface ListItemProps {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  useTaskSyncing(props.id);
  const history = useHistory();
  const classes = makeListItemStyles();
  const { listId } = useParams();

  return (
    <ButtonBase
      focusRipple
      component="a"
      className={`list-item ${classes['list-item']} ${
        listId === props.id ? 'active' : ''
      }`}
      onClick={() => {
        history.push(`/lists/${props.id}`);
      }}
    >
      <div className="list-item__icon">
        <ListIcon filename={props.icon} />
      </div>
      <div className="list-item__text">
        <div className={`list-item__title ${classes['list-item__title']}`}>
          {props.title}
        </div>
        <div className="list-item__subtitle">{props.subtitle}</div>
      </div>
    </ButtonBase>
  );
};
