import React from 'react';
import { IconButton } from '@material-ui/core';
import { SnoozeIcon } from '../../ui/icons';

export const SnoozeButton: React.FC<{
  taskId: string;
  listId: string;
  openMenu: (
    taskId: string,
    listId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}> = (props) => {
  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="Snooze"
        aria-controls="snooze-menu"
        aria-haspopup="true"
        focusRipple
        onClick={(e) => {
          e.stopPropagation();
          props.openMenu(props.taskId, props.listId, e);
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
      >
        <SnoozeIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default SnoozeButton;
