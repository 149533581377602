import { LoggedOutAction } from '../auth/types';

// Entity Interfaces
export interface List {
  id: string;
  createdBy: string;
  name: string;
  description: string;
  icon: string;
}
export interface DBList {
  createdBy: string;
  name: string;
  description?: string;
  icon?: string;
}

// State Interfaces
export type ListState = Array<List>;

// Describing the different ACTION NAMES available
export const LIST_UPDATE_RECEIVED = 'LIST_UPDATE_RECEIVED';
export const LIST_REMOVED_RECEIVED = 'LIST_REMOVED_RECEIVED';

// Action Interfaces
export interface ListUpdateReceived {
  type: typeof LIST_UPDATE_RECEIVED;
  list: List;
}
export interface ListRemovedReceived {
  type: typeof LIST_REMOVED_RECEIVED;
  listId: string;
}

// Action Union
export type ListActionTypes =
  | ListUpdateReceived
  | ListRemovedReceived
  | LoggedOutAction;
