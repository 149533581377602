// Entity Interfaces
export interface User {
  uid: string;
  displayName?: string | null;
  email?: string | null;
  emailVerified?: boolean;
  isAnonymous: boolean;
  photoURL?: string | null;
}

// State Interfaces
export interface AuthState {
  user: User | null;
  authStateDetermined: boolean;
}

// Describing the different ACTION NAMES available
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

// Action Interfaces
export interface LoggedInAction {
  type: typeof LOGGED_IN;
  payload: User;
}

export interface LoggedOutAction {
  type: typeof LOGGED_OUT;
}

// Action Union
export type AuthActionTypes = LoggedInAction | LoggedOutAction;
