import { createStore, combineReducers, applyMiddleware, Action } from 'redux';
import thunkMiddleware, { ThunkAction, ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer } from './auth/reducers';
import { listReducer } from './list/reducers';
import { systemReducer } from './system/reducers';
import { taskReducer } from './task/reducers';
import { taskRefresh } from './task/actions';
import { themeReducer } from './theme/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  lists: listReducer,
  system: systemReducer,
  tasks: taskReducer,
  theme: themeReducer,
});

// For redux typing https://github.com/reduxjs/redux-thunk/blob/master/test/typescript.ts#L60
export type AppState = ReturnType<typeof rootReducer>;
export type ThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  Action<string>
>;

export function configureStore() {
  const middleWareEnhancer = applyMiddleware(
    thunkMiddleware as ThunkMiddleware<AppState, Action<string>>
  );

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}

export const store = configureStore();

function refreshTasksTomorrow() {
  const tomorrow = new Date();
  tomorrow.setHours(24, 0, 0, 0);

  process.env.NODE_ENV !== 'test' &&
    console.log('Tasks will be refreshed tomorrow:', tomorrow);
  let timer = setTimeout(() => {
    store.dispatch(taskRefresh());
    refreshTasksTomorrow();
  }, tomorrow.getTime() - new Date().getTime());

  return () => clearTimeout(timer);
}

refreshTasksTomorrow();

export type AppDispatch = typeof store.dispatch;
