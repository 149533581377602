import { SWITCH_THEME } from './types';
import { ThunkResult } from '..';
import { snackbarSuccess } from '../system/actions';

export function switchTheme(
  themeType: 'light' | 'dark' | 'auto'
): ThunkResult<void> {
  return async (dispatch) => {
    localStorage.setItem('themeType', themeType);
    dispatch({
      type: SWITCH_THEME,
      themeType,
    });
    dispatch(snackbarSuccess(`Switched to ${themeType.toUpperCase()} Theme`));
  };
}
