import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import './DashboardPage.css';
import { makeDashboardPageStyles } from './DashboardPage.styles';
import { ListList } from './lists/ListList';
import { AppState } from '../../store';
import { useListSyncing } from '../../hooks/useListSyncing';
import DashboardDetails from './DashboardDetails';
import { listSelector, activeListSelector } from '../../selectors/list';
import { LIST_ID_INBOX } from '../../selectors/task';
import ListAddButton from './lists/ListAddButton';
import { MenuIcon } from '../ui/icons';
import { Weather } from '../ui/icons/Weather';
import NavigationDrawer from './NavigationDrawer';

export const DashboardPage: React.FC<ConnectedProps<typeof connector>> = ({
  dynamicLists,
  userLists,
  activeList,
  activeTask,
  editList,
  addTask,
  editTask,
}) => {
  useListSyncing();
  const [showsDrawer, setShowsDrawer] = useState(false);
  const classes = makeDashboardPageStyles();

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setShowsDrawer(open);
  };

  return (
    <div className="dashboard">
      <div className={`dashboard__master ${classes.dashboard__master}`}>
        <div className="dashboard__master-header">
          <IconButton
            aria-label="menu"
            color="inherit"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Weather />
        </div>

        {/* <Search /> */}

        <div style={{ overflow: 'scroll' }}>
          <ListList lists={dynamicLists} />
          <hr className={classes.hr} />
          <ListList lists={userLists} />
        </div>

        <div className="dashboard__list-add">
          <ListAddButton />
        </div>
      </div>

      <div className={`dashboard__detail ${classes.dashboard__detail}`}>
        {activeList ? (
          <DashboardDetails
            list={activeList}
            editList={editList}
            addTask={addTask}
            editTask={editTask}
            taskDetails={activeTask}
          />
        ) : (
          <div>TODO: Not Found</div>
        )}
      </div>

      <NavigationDrawer open={showsDrawer} onClose={toggleDrawer(false)} />
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  props: {
    selectedList?: string;
    selectedTask?: string;
    editList?: boolean;
    addTask?: boolean;
    editTask?: boolean;
  }
) => {
  const selectedLists = state.auth.user
    ? listSelector(state.auth.user.uid, state.lists, state.tasks)
    : { dynamicLists: [], userLists: [] };

  const activeList = state.auth.user
    ? activeListSelector(
        state.auth.user.uid,
        state.lists,
        props.selectedList || LIST_ID_INBOX
      )
    : undefined;

  const activeTask =
    props.selectedTask === undefined
      ? undefined
      : state.tasks.find((t) => t.id === props.selectedTask);

  return {
    ...selectedLists,
    activeList,
    activeTask,
    editList: props.editList,
    addTask: props.addTask,
    editTask: props.editTask,
  };
};

const connector = connect(mapStateToProps, {});

export default connector(DashboardPage);
