import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ColorBox } from '../../ui/ColorBox';
import { startResetPassword } from '../../../store/auth/actions';
import { AppDispatch } from '../../../store';
import { MailField } from '../../forms/MailField';

export const PasswordResetPage: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const history = useHistory();

  const { register, errors, handleSubmit } = useForm<State>({
    validationSchema,
  });

  const onSubmit = (data: State) => {
    props.startResetPassword(data.email);
  };

  return (
    <div className="center-box">
      <div style={{ padding: '4.8rem' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <ColorBox
            submitButton={true}
            onBack={() => {
              history.goBack();
            }}
          >
            <div className="color-box__header">
              <h2>Forgott Password?</h2>
              <span>
                Enter your E-Mail address and we will send you instructions to
                reset your password.
              </span>
            </div>

            <MailField
              name="email"
              inputRef={register()}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              style={{ marginBottom: '4rem' }}
            />
          </ColorBox>
        </form>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch: AppDispatch) => ({
  startResetPassword: (email: string) => {
    dispatch(startResetPassword(email));
  },
});

const connector = connect(undefined, mapDispatch);

interface State {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
});

export default connector(PasswordResetPage);
