import React, { useState } from 'react';
import { ReactComponent as EmptyStateIlluDark } from './images/illu_empty_ondark.svg';
import { ReactComponent as OnboardingIlluDark } from './images/illu_onboarding_ondark.svg';
import { ReactComponent as EmptyStateIlluLight } from './images/illu_empty_onlight.svg';
import { ReactComponent as OnboardingIlluLight } from './images/illu_onboarding_onlight.svg';
import { useTheme } from '@material-ui/core/styles';

export const EmptyStateIllustration: React.FC = () => {
  const theme = useTheme();
  const [choice] = useState(Math.floor(Math.random() * 2));

  let Illustration: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  if (theme.palette.type === 'dark') {
    Illustration = [EmptyStateIlluDark, OnboardingIlluDark][choice];
  } else {
    Illustration = [EmptyStateIlluLight, OnboardingIlluLight][choice];
  }
  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '6.8rem',
      }}
    >
      <Illustration />
    </div>
  );
};
