import { makeStyles } from '@material-ui/core/styles';

export const makeNavigationDrawerStyles = makeStyles((theme) => ({
  header: {
    '& .avatar': { width: '4.8rem', height: '4.8rem', marginBottom: '2.4rem' },
    background: theme.colorDrawer,
    marginBottom: '0.8rem',
    padding: '2.4rem',
    maxWidth: '28rem',
  },
  header__icons: { display: 'flex', justifyContent: 'space-between' },
  header__theme: { margin: '-1.2rem', color: '#888' },
  header__name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  header__email: {
    color: '#888',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  list: {
    margin: '0 1.2rem',
    width: '25.6rem',
  },
  item: {
    '& .MuiTypography-root ': { fontSize: '1.4rem', lineHeight: '2rem' },
    '& .MuiListItemIcon-root': { color: '#888' },
    borderRadius: '4px',
    marginBottom: '1.2rem',
  },
  divider: {
    margin: '0 0 0.8rem 0',
    background:
      theme.palette.type === 'dark'
        ? 'rgba(0, 0, 0, 0.2)'
        : 'rgba(0, 0, 0, 0.1)',
  },
}));
