import { makeStyles } from '@material-ui/core/styles';

export const makeDashboardDetailsActionMenuStyles = makeStyles((theme) => ({
  dialog: {
    background: theme.colorBox,
    borderRadius: '8px',
    boxShadow:
      theme.palette.type === 'light'
        ? '0px 4px 12px rgba(0, 0, 0, 0.12)'
        : 'none',
  },
  menu: {
    background: theme.colorBox,
    marginTop: '0.8rem',
    boxShadow:
      '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
  },
}));
