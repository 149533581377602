import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CssBaseline } from '@material-ui/core';
import AppRouter from './router/AppRouter';
import { darkTheme, lightTheme } from '../theme';
import ConsecutiveSnackbars from './ui/ConsecutiveSnackbars';
import LoadingSpinner from './ui/LoadingSpinner';
import { AppState } from '../store';
import FirebaseAnalytics from './FirebaseAnalytics';

export const App: React.FC<ConnectedProps<typeof connector>> = ({
  themeType,
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      themeType === 'dark' || (themeType === 'auto' && prefersDarkMode)
        ? darkTheme
        : lightTheme,
    [prefersDarkMode, themeType]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRouter />
      <ConsecutiveSnackbars />
      <LoadingSpinner />
      <FirebaseAnalytics />
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  themeType: state.theme.themeType,
});
const connector = connect(mapStateToProps, {});

export default connector(App);
