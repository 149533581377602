import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ColorBox } from '../../ui/ColorBox';
import { updateProfile } from '../../../store/auth/actions';
import { AppDispatch, AppState } from '../../../store';
import { TextField } from '../../forms/TextField';

export const ProfileUpdatePage: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const history = useHistory();

  const { register, errors, handleSubmit } = useForm<State>({
    validationSchema,
    defaultValues: { name: props.name },
  });

  const onSubmit = (data: State) => {
    props.updateProfile(data.name);
    props.onClose && props.onClose();
  };

  return (
    <div className="center-box">
      <div style={{ padding: '4.8rem' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <ColorBox
            submitButton={true}
            onBack={() => {
              history.goBack();
            }}
          >
            <TextField
              name="name"
              label="Name"
              inputRef={register()}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              style={{ marginBottom: '4rem' }}
            />
          </ColorBox>
        </form>
      </div>
    </div>
  );
};

interface State {
  name: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required().min(3),
});

const mapStateToProps = (
  state: AppState,
  props: {
    onClose?: () => void;
  }
) => {
  return {
    name: state.auth.user?.displayName || undefined,
    ...props,
  };
};

const mapDispatch = (dispatch: AppDispatch) => ({
  updateProfile: (displayName: string) => {
    dispatch(updateProfile({ displayName }));
  },
});

const connector = connect(mapStateToProps, mapDispatch);

export default connector(ProfileUpdatePage);
