import {
  User,
  LOGGED_IN,
  LOGGED_OUT,
  LoggedInAction,
  LoggedOutAction,
} from './types';
import firebase from '../../firebase';
import { ThunkResult } from '..';
import {
  operatoinStarted,
  operatoinSucceeded,
  operatoinFailed,
} from '../system/actions';

export function startAnonymousSignIn(): ThunkResult<void> {
  return async (dispatch) => {
    try {
      dispatch(operatoinStarted());
      await firebase.auth().signInAnonymously();
      dispatch(operatoinSucceeded('Logged in as guest'));
    } catch (error) {
      dispatch(operatoinFailed(error.message));
    }
  };
}

export function startEmailPasswordSignUp(
  email: string,
  password: string
): ThunkResult<void> {
  return async (dispatch) => {
    try {
      dispatch(operatoinStarted());
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      dispatch(operatoinSucceeded('Account created successfully'));
    } catch (error) {
      dispatch(operatoinFailed(error.message));
    }
  };
}

export function startEmailPasswordSignIn(
  email: string,
  password: string
): ThunkResult<void> {
  return async (dispatch) => {
    try {
      dispatch(operatoinStarted());
      await firebase.auth().signInWithEmailAndPassword(email, password);
      dispatch(operatoinSucceeded(`Logged in as ${email}`));
    } catch (error) {
      dispatch(operatoinFailed(error.message));
    }
  };
}

export function updateProfile(updates: {
  displayName?: string;
}): ThunkResult<void> {
  return async (dispatch) => {
    try {
      dispatch(operatoinStarted());

      const currentUser = firebase.auth().currentUser;
      if (currentUser === null) {
        dispatch(
          operatoinFailed(
            'You must be authenticated to update your user profile'
          )
        );
      } else {
        await currentUser.updateProfile(updates);
        dispatch(operatoinSucceeded('User profile updated'));
      }
    } catch (error) {
      dispatch(operatoinFailed(error.message));
    }
  };
}

export function startResetPassword(email: string): ThunkResult<void> {
  return async (dispatch) => {
    try {
      dispatch(operatoinStarted());
      await firebase.auth().sendPasswordResetEmail(email);
      dispatch(
        operatoinSucceeded(`Sent password reset instructions to ${email}`)
      );
    } catch (error) {
      dispatch(operatoinFailed(error.message));
    }
  };
}

export function loggedIn(user: User): LoggedInAction {
  return {
    type: LOGGED_IN,
    payload: user,
  };
}

export function loggedOut(): LoggedOutAction {
  return {
    type: LOGGED_OUT,
  };
}

export function startLogout(): ThunkResult<void> {
  return async (dispatch) => {
    try {
      dispatch(operatoinStarted());
      await firebase.auth().signOut();
      dispatch(loggedOut());
      dispatch(operatoinSucceeded('Logged Out'));
    } catch (error) {
      dispatch(operatoinFailed(error.message));
    }
  };
}
