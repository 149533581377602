import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './SignupPage.css';
import LoginForm from '../login/LoginForm';
import { LogoIcon } from '../../ui/icons';
import { startAnonymousSignIn } from '../../../store/auth/actions';
import { AppState, AppDispatch } from '../../../store';
import { makeButtonStyles } from '../../forms/styles';

export const SignupPage: React.FC<ConnectedProps<typeof connector>> = (
  props
) => {
  const classes = makeButtonStyles();
  const history = useHistory();

  return (
    <div className="signup">
      <div className="signup__container">
        <a
          className={`signup__logo`}
          href="https://wando.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoIcon />
        </a>
        <div className="signup__register">
          <h1 className="signup__title">
            Show your tasks some love from now on
          </h1>
          <div className="signup__subtitle">
            Create a new account in just 2 minutes and import your existing
            Wunderlist tasks
          </div>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.button}
            style={{ marginBottom: '1.6rem' }}
            onClick={() => {
              history.push('/signup/email');
            }}
          >
            Create&nbsp;new&nbsp;account
          </Button>
          <Button
            variant="outlined"
            fullWidth
            className={classes.button}
            onClick={props.startAnonymousSignIn}
          >
            Use&nbsp;Wando&nbsp;anonymously
          </Button>
        </div>
        <div className="signup__spacer"></div>
        <div className="signup__login">
          <LoginForm />
        </div>
      </div>
      <div className="signup__footer">Terms &amp; Conditions</div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatch = (dispatch: AppDispatch) => ({
  startAnonymousSignIn: () => {
    dispatch(startAnonymousSignIn());
  },
});

const connector = connect(mapStateToProps, mapDispatch);

export default connector(SignupPage);
