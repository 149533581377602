import { makeStyles } from '@material-ui/core/styles';

export const makeSnoozeMenuStyles = makeStyles((theme) => ({
  menu: {
    minWidth: '25.8rem',
    background: theme.colorBox,
    boxShadow:
      '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)',
  },
}));
