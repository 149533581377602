import React from 'react';
import { TextField } from '@material-ui/core';
import { makeInputStyles } from './styles';

export const MailField: typeof TextField = (props) => {
  const classes = makeInputStyles();

  return (
    <TextField
      {...props}
      type="email"
      label="E-Mail"
      variant="filled"
      color="secondary"
      fullWidth
      className={classes.FormControl}
      InputLabelProps={{ className: classes.label }}
      InputProps={{ className: classes.input }}
    />
  );
};
